import React from 'react';
import Styles from './styled.js';
import { NavLink } from 'react-router-dom';
import VMFLogo from '../../../VMFLogo/VMFLogo.js';
import ScrollLink from '../../../../components/ScrollLink';
import ButtonLink from '../../../Shared/ButtonLink.js';
import useGTM from '../../../../hooks/useGTM.js';
import { getBaseEscoreApiUrl } from '../../../../util/config.js';
import createGTMClass from '../../../../util/createGTMClass.js';

export default ({
  hideButtons = false,
  isDark = false,
  children,
  hideNavigation = false,
}) => {
  const [triggerGTMEvent] = useGTM();

  return (
    <Styles isDark={isDark}>
      <div className='logo'>
        <NavLink to='/' className={createGTMClass('link', 'header', 'logo')}>
          <VMFLogo style='color' />
          <p>Home</p>
        </NavLink>
      </div>
      {hideNavigation && (
        <ul className='primary'>
          <li className='menu-item'>
            <ScrollLink href='#overview'>Overview</ScrollLink>
          </li>
          <li className='menu-item'>
            <ScrollLink href='#what-we-finance'>What we finance</ScrollLink>
          </li>
          <li className='menu-item'>
            <ScrollLink href='#how-to-apply'>How to apply</ScrollLink>
          </li>
          <li className='menu-item'>
            <ScrollLink href='#faqs'>FAQs</ScrollLink>
          </li>
          <li className='menu-item'>
            <ScrollLink href='#about-us'>About us</ScrollLink>
          </li>
        </ul>
      )}

      {hideButtons && (
        <div className='secondary'>
          <ButtonLink
            to={`${getBaseEscoreApiUrl()}/Account/Login`}
            appearance='light'
            hoverAppearance='dark'
            size='small'
            isExternal={true}
            newTab={true}
            className={createGTMClass('sign_in', 'header', 'button')}
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'All Pages',
              category: 'account_sign_in',
              action: 'nav',
              label: 'sign_in',
            })}
          >
            Sign In
          </ButtonLink>
          <ButtonLink
            to='https://energyright.com/residential/financing/'
            appearance='dark'
            hoverAppearance='light'
            size='small'
            isExternal={true}
            newTab={true}
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'All Pages',
              category: 'account_sign_in',
              action: 'nav',
              label: 'sign_in',
            })}
          >
            TVA EnergyRight
          </ButtonLink>
        </div>
      )}

      {children}
    </Styles>
  );
};
