import React from 'react';
import Styles from './styled.js';
import { NavLink } from 'react-router-dom';
import TempVMFLogo from '../../../../../public/images/TempVMF1.png';
import ScrollLink from '../../../../components/ScrollLink';
import ButtonLink from '../../../Shared/ButtonLink.js';
import useGTM from '../../../../hooks/useGTM.js';
import { getBaseEscoreApiUrl } from '../../../../util/config.js';

export default ({
  hideButtons = false,
  isDark = false,
  children,
  hideNavigation = false,
}) => {
  const [triggerGTMEvent] = useGTM();

  return (
    <Styles isDark={isDark}>
      <div className='logo'>
        <NavLink to='/' target='_blank'>
          <img width='150px' src={TempVMFLogo} />
        </NavLink>
      </div>
    </Styles>
  );
};
