import React from 'react';

export default ({ isOpen = false }) => (
  <svg
    width='23'
    height='23'
    viewBox='0 0 23 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={`menu_toggle ${
      isOpen ? 'menu_toggle--open' : 'menu_toggle--closed'
    }`}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M 2 2.5 L 20 2.5'
      fill='transparent'
      strokeWidth='3'
      stroke='currentColor'
      strokeLinecap='round'
      className='menu_toggle__top'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M 2 9.423 L 20 9.423'
      fill='transparent'
      strokeWidth='3'
      stroke='currentColor'
      strokeLinecap='round'
      className='menu_toggle__mid'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M 2 16.346 L 20 16.346'
      fill='transparent'
      strokeWidth='3'
      stroke='currentColor'
      strokeLinecap='round'
      className='menu_toggle__bottom'
    />
  </svg>
);
