import styled from 'styled-components';

export default styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  .contact {
    margin-left: 2.3125rem;
    border-left: solid 1px ${props => props.theme.colors.grey4};
    padding-left: 2rem;

    a {
      padding: 1rem 0;
      display: block;
      display: flex;
      height: 100%;
      align-items: center;
      font-size: ${props => props.theme.type.h5};
      font-family: ${props => props.theme.fonts.secondary};
      font-weight: 800;
      color: ${props =>
        props.isDark ? props.theme.colors.white : props.theme.colors.vmfBlue};
      text-decoration: none;

      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .ctas {
    display: flex;
    flex-direction: row;

    a {
      transform: translateZ(0);

      &:first-child {
        margin-right: 1rem;
      }
    }
  }
`;
