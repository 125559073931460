import styled from 'styled-components';

export default styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  padding-right: 1rem;

  a {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: ${props => props.theme.type.h5};
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 800;
    color: ${props =>
      props.isDark ? props.theme.colors.white : props.theme.colors.vmfBlue};
    text-decoration: none;

    svg {
      margin-right: 0.5rem;
    }
  }
`;
