import React, { useMemo } from 'react';
import PropTypes, { oneOf } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import ButtonLinkStyles from './ButtonLink.styled';
import CustomUrl from '../../util/url/CustomUrl';

const ConditionalLink = ({
  children,
  appearance = 'dark',
  hoverAppearance = 'dark',
  outline = false,
  size = 'large',
  to = '',
  newTab = false,
  disabled = false,
  isExternal = false,
  className = '',
  contentfulColors = '',
  inverse = false,
  carryQuery = true,
  ...props
}) => {
  const location = useLocation();
  const search = location.search;

  const fullUrl = useMemo(() => {
    if (carryQuery) {
      const pathWithQuery = new CustomUrl(to);
      pathWithQuery.joinQuery(search);

      return pathWithQuery.fullUrl;
    }

    return to;
  }, [to, search, carryQuery]);

  return isExternal ? (
    <a
      href={fullUrl}
      target={newTab ? '_blank' : '_self'}
      rel='noopener noreferrer'
      className={`button-link ${className}`}
      {...props}
    >
      {children}
    </a>
  ) : (
    <Link to={fullUrl} {...props} className={`button-link ${className}`}>
      {children}
    </Link>
  );
};

const ButtonLink = ButtonLinkStyles(ConditionalLink);

ConditionalLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  appearance: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['large', 'small']),
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  to: PropTypes.string,
  isExternal: PropTypes.bool,
  newTab: PropTypes.bool,
};

export default ButtonLink;
