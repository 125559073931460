import React from 'react';
import Styles from './styled';
import { Link } from 'react-router-dom';
import ButtonLink from '../../../Shared/ButtonLink';
import PhoneIcon from '../../../Shared/Icons/Phone';
import noop from '../../../../util/noop';
import useGTM from '../../../../hooks/useGTM';
import { getBaseHugUrl } from '../../../../util/config';

export default ({ isContractor = false, closeMenu = noop }) => {
  const [triggerGTMEvent] = useGTM();

  const hugPage = window.location.pathname;

  const handleButton1Click = () => {
    if (!isContractor) {
      triggerGTMEvent({
        name: 'vmf.cta_click',
        location: 'B2B Navigation',
        category: 'account_sign_in',
        action: 'text',
        label: 'sign_in',
      })();
    }
    closeMenu();
  };

  const handleButton2Click = () => {
    triggerGTMEvent({
      name: 'vmf.cta_click',
      location: isContractor ? 'B2B Navigation' : 'B2C Navigation',
      category: isContractor ? 'account_sign_in' : 'loan_application',
      action: isContractor ? 'text' : 'nav',
      label: isContractor ? 'sign_in' : 'apply_today',
    })();
    close();
  };

  const handlePreHeaderClick = () => {
    triggerGTMEvent({
      name: 'vmf.cta_click',
      location: isContractor ? 'B2B Navigation' : 'B2C Navigation',
      category: 'loan_education',
      action: 'nav',
      label: isContractor ? 'for_homeowners' : 'for_contractors',
    })();
    close();
  };

  return (
    <Styles>
      <section className='primary'>
        <ButtonLink
          to={
            isContractor
              ? '/contractor-enroll/contact-us/'
              : `${getBaseHugUrl()}/home-upgrade-loan/Auth/Login`
          }
          appearance='dark'
          outline
          size='small'
          isExternal={!isContractor}
          onClick={handleButton1Click}
          className='button-link-vmf-blue'
        >
          {isContractor ? 'Contact us' : 'Sign in'}
        </ButtonLink>
        {hugPage === '/home-upgrade-loan/availability/' ||
        hugPage === '/home-upgrade-loan/availability' ? (
          ''
        ) : (
          <ButtonLink
            to={
              isContractor
                ? 'https://vmf.energydatametrics.com/Contractor/Signin.aspx'
                : '/home-upgrade-loan/availability/'
            }
            appearance='dark'
            size='small'
            isExternal={isContractor}
            onClick={handleButton2Click}
          >
            {isContractor ? 'Sign in' : 'Apply today'}
          </ButtonLink>
        )}

        {isContractor && (
          <a
            className='contact'
            href='tel:18888938792'
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'B2B Navigation',
              category: 'contact_us',
              action: 'phone',
              label: 'click_to_call',
            })}
          >
            <PhoneIcon /> 1-888-893-8792
          </a>
        )}
      </section>
      <section className='secondary'>
        <Link
          onClick={handlePreHeaderClick}
          to={isContractor ? '/home-upgrade-loan/' : '/contractor-enroll/'}
        >
          For {isContractor ? 'homeowners' : 'contractors'}
        </Link>
        <Link
          className='back'
          to='/'
          onClick={() => {
            triggerGTMEvent({
              name: 'vmf.cta_click',
              location: isContractor ? 'B2B Navigation' : 'B2C Navigation',
              category: 'loan_education',
              action: 'nav',
              label: isContractor ? 'for_homeowners' : 'for_contractors',
            })();
            closeMenu();
          }}
        >
          Visit VMF.com
        </Link>
      </section>
    </Styles>
  );
};
