import React from 'react';
import PropTypes from 'prop-types';

function ChevronDown() {
  return (
    <svg
      width='10'
      height='6'
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L4.64645 5.35355C4.84171 5.54882 5.15829 5.54882 5.35355 5.35355L9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L5 4.29289L1.35355 0.646447Z'
        fill='white'
      />
    </svg>
  );
}

ChevronDown.propTypes = {};

export default React.memo(ChevronDown);
