import axios from 'axios';
import { getBaseApiUrl, getRequestHeaders } from '../util/config';

export const getAlerts = async () =>
  await axios.get(`${getBaseApiUrl()}/Contentful/Alerts`, {
    headers: getRequestHeaders(),
  });

export const getAlert = async pageLink =>
  await axios.get(`${getBaseApiUrl()}/Contentful/Alert?pageLink=${pageLink}`, {
    headers: getRequestHeaders(),
  });
