import React from 'react';
import Styles from './styled';
import { Link } from 'react-router-dom';
import useGTM from '../../../../hooks/useGTM';
import createGTMClass from '../../../../util/createGTMClass';

export default ({ isContractor = false }) => {
  const [triggerGTMEvent] = useGTM();

  const handleVMFLinkClick = () => {
    triggerGTMEvent({
      name: 'vmf.cta_click',
      location: isContractor ? 'B2B Navigation' : 'B2C Navigation',
      category: 'loan_education',
      action: 'nav',
      label: 'vmf.com_text',
    })();
  };

  return (
    <Styles>
      <ul>
        <li>
          <Link
            className={createGTMClass('vmf', 'preheader', 'text')}
            onClick={handleVMFLinkClick}
            to='/'
          >
            VMF.com
          </Link>
        </li>
        <li>
          <Link
            className={createGTMClass('link', 'preheader', 'text')}
            to={isContractor ? '/home-upgrade-loan/' : '/contractor-enroll/'}
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: isContractor ? 'B2B Navigation' : 'B2C Navigation',
              category: 'loan_education',
              action: 'nav',
              label: isContractor ? 'for_homeowners' : 'for_contractors',
            })}
          >
            {isContractor ? 'For Homeowners' : 'For Contractors'}
          </Link>
        </li>
      </ul>
    </Styles>
  );
};
