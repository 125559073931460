import React from 'react';

const ArrowIcon = () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.9615 4.29289C12.571 3.90237 11.9378 3.90237 11.5473 4.29289C11.1568 4.68342 11.1568 5.31658 11.5473 5.70711L16.8402 11H5.25439C4.70211 11 4.25439 11.4477 4.25439 12C4.25439 12.5523 4.70211 13 5.25439 13H16.8402L11.5473 18.2929C11.1568 18.6834 11.1568 19.3166 11.5473 19.7071C11.9378 20.0976 12.571 20.0976 12.9615 19.7071L19.9608 12.7078C19.9632 12.7054 19.9656 12.703 19.968 12.7005C20.1445 12.5208 20.2536 12.2746 20.2544 12.003C20.2544 12.002 20.2544 12.001 20.2544 12C20.2544 11.999 20.2544 11.998 20.2544 11.997C20.254 11.8625 20.227 11.7343 20.1785 11.6172C20.1308 11.502 20.0607 11.3938 19.968 11.2995C19.9656 11.297 19.9633 11.2947 19.9609 11.2923M12.9615 4.29289L19.9609 11.2923L12.9615 4.29289Z'
      fill='currentColor'
    />
  </svg>
);

export default ArrowIcon;
