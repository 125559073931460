import React from 'react';
import Phone from '../../../../components/Shared/Icons/Phone';
import { useAppContext } from '../../../../contexts/AppContext';
import Styles from './styled';
import useGTM from '../../../../hooks/useGTM';

export default () => {
  const [{ headerContactNumber }] = useAppContext();
  const [triggerGTMEvent] = useGTM();

  return (
    <Styles>
      <a
        href={`tel:${headerContactNumber?.replace(/-/gim, '')}`}
        onClick={triggerGTMEvent({
          name: 'vmf.cta_click',
          location: 'phone number in top nav',
          category: 'contact_us',
          action: 'click_to_call',
          label: 'call_landing_page_phone',
        })}
      >
        <Phone />
        {headerContactNumber}
      </a>
    </Styles>
  );
};
