import React from 'react';
import Styles from './styled';
import { NavLink } from 'react-router-dom';
import useGTM from '../../../../hooks/useGTM';
import noop from '../../../../util/noop';
import VMFLogo from '../../../VMFLogo/VMFLogo';
import { useAppContext } from '../../../../contexts/AppContext';
import createGTMClass from '../../../../util/createGTMClass';

const landingPageLocations = {
  '/applynow/welcome/': 'Application Page 1',
  '/applynow/welcome': 'Application Page 1',
  '/applynow/welcome2/': 'Application Page 2',
  '/applynow/welcome2': 'Application Page 2',
};

export default ({
  closeMenu = noop,
  isDark = false,
  isLandingPage = false,
  children,
  headerLogoUrl = '/',
}) => {
  const [triggerGTMEvent] = useGTM();

  return (
    <Styles isDark={isDark}>
      <div className='logo'>
        <NavLink
          to={headerLogoUrl}
          onClick={
            isLandingPage
              ? triggerGTMEvent({
                  name: 'vmf.cta_click',
                  location: landingPageLocations[window.location.pathname],
                  category: 'brand_evaluation',
                  action: 'logo',
                  label: 'vmf_homepage_exit',
                })
              : null
          }
          className={createGTMClass('vmf', 'header', 'logo', [])}
        >
          <VMFLogo style='color' />
          <p>Home</p>
        </NavLink>
      </div>
      {children}
    </Styles>
  );
};
