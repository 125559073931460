import React from 'react';

export default () => (
  <svg
    width='18'
    height='19'
    viewBox='0 0 18 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.9247 17.9553C14.8497 17.9553 14.7747 17.9553 14.6997 17.9553C12.2997 17.6553 9.89972 16.8303 7.87472 15.5553C5.99972 14.3553 4.34972 12.7053 3.14972 10.8303C1.87472 8.80527 1.04972 6.40527 0.749722 4.00527C0.674722 3.40527 0.899722 2.80527 1.27472 2.35527C1.64972 1.90527 2.17472 1.60527 2.77472 1.53027C2.84972 1.53027 2.92472 1.53027 2.99972 1.53027H5.24972C6.37472 1.53027 7.34972 2.35527 7.49972 3.48027C7.57472 4.15527 7.72472 4.83027 7.94972 5.43027C8.24972 6.25527 8.09972 7.15527 7.49972 7.75527L6.89972 8.35527C7.79972 9.70527 8.92472 10.9053 10.3497 11.8053L10.8747 11.2803C11.4747 10.6803 12.4497 10.4553 13.2747 10.7553C13.8747 10.9803 14.5497 11.1303 15.2247 11.2053C16.3497 11.3553 17.1747 12.3303 17.1747 13.4553V15.7053C17.1747 16.3053 16.9497 16.9053 16.4997 17.2803C16.0497 17.6553 15.5247 17.9553 14.9247 17.9553ZM5.24972 3.03027H2.99972C2.69972 3.03027 2.54972 3.18027 2.39972 3.33027C2.32472 3.48027 2.24972 3.63027 2.24972 3.85527C2.47472 6.03027 3.22472 8.13027 4.42472 10.0053C5.54972 11.7303 6.97472 13.2303 8.69972 14.2803C10.5747 15.4803 12.6747 16.2303 14.8497 16.4553C15.1497 16.4553 15.2997 16.3803 15.4497 16.2303C15.5997 16.0803 15.6747 15.9303 15.6747 15.7053V13.4553C15.6747 13.0803 15.3747 12.7803 14.9997 12.7053C14.2497 12.6303 13.4997 12.4053 12.7497 12.1053C12.4497 12.0303 12.1497 12.0303 11.9247 12.2553L10.9497 13.2303C10.7247 13.4553 10.3497 13.5303 10.0497 13.3803C8.02472 12.2553 6.37472 10.6053 5.24972 8.58027C5.17472 8.28027 5.24972 7.90527 5.47472 7.68027L6.44972 6.70527C6.67472 6.48027 6.74972 6.18027 6.59972 5.88027C6.29972 5.13027 6.14972 4.38027 5.99972 3.63027C5.92472 3.33027 5.62472 3.03027 5.24972 3.03027Z'
      fill='currentColor'
    />
  </svg>
);
