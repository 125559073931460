import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
  }
  
  body {
    margin: 0;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.grey1};
    overflow-y: scroll;
  }

  /* Osano Styles */
  .osano-cm-widget {
    display: none;
  }

  .osano-cm-dialog {
    display: none;
  }

  /*Sizes Privacy Policy Notice*/
  body > div {
    box-sizing: border-box;
  }

  main {
    display: block;
  }

  .bold {
    font-weight: 800;
  }

  sup {
    font-size: 0.5em;
    display: inline-block;
  }

  h1, h2, h3, h4, h5, h6, .type-h1, .type-h2, .type-h3, .type-h4, .type-h5, .type-h6 {
    color: ${props => props.theme.colors.darkBlue};
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 800;
  }

  ul {
    padding: 0 0 0 1rem;

    li {
      padding: .5rem 0 .5rem .5rem;
      font-size: ${props => props.theme.type.paragraph.default};
      line-height: ${props => props.theme.lineHeights.paragraph.default};
    }
  }

  p, li {
    a {
      color: ${props => props.theme.colors.vmfBlue};
      transition: all .4s ease-in-out;
  
      &:hover, &:active, &:focus {
        color: ${props => props.theme.colors.vmfGreen2};
      }
    }
  }

  h1, .type-h1 {
    font-size: ${props => props.theme.type.h1};
    line-height: ${props => props.theme.lineHeights.h1};
    letter-spacing: ${props => props.theme.letterSpacing.h1};

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.type.h1Mobile};
      line-height: ${props => props.theme.lineHeights.h1Mobile};
    }
  }

  h2, .type-h2 {
    font-size: ${props => props.theme.type.h2};
    line-height: ${props => props.theme.lineHeights.h2};
    letter-spacing: ${props => props.theme.letterSpacing.h2};
    
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.type.h2Mobile};
      line-height: ${props => props.theme.lineHeights.h2Mobile};
    }
  }

  h3, .type-h3 {
    font-size: ${props => props.theme.type.h3};
    line-height: ${props => props.theme.lineHeights.h3};
    letter-spacing: ${props => props.theme.letterSpacing.h3};

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.type.h3Mobile};
      line-height: ${props => props.theme.lineHeights.h3Mobile};
    }
  }

  h4, .type-h4 {
    font-size: ${props => props.theme.type.h4};
    line-height: ${props => props.theme.lineHeights.h4};
    letter-spacing: ${props => props.theme.letterSpacing.h4};
    
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.type.h4Mobile};
      line-height: ${props => props.theme.lineHeights.h4Mobile};
    }
  }

  h5, .type-h5 {
    font-size: ${props => props.theme.type.h5};
    line-height: ${props => props.theme.lineHeights.h5};
    letter-spacing: ${props => props.theme.letterSpacing.h5};
  }

  h6, .type-h6 {
    font-size: ${props => props.theme.type.h6};
    line-height: ${props => props.theme.lineHeights.h6};
    letter-spacing: ${props => props.theme.letterSpacing.h6};
  }

  p, .type-p {
    font-size: ${props => props.theme.type.paragraph.default};
    line-height: ${props => props.theme.lineHeights.paragraph.default};
    letter-spacing: ${props => props.theme.letterSpacing.paragraph};
    font-family: ${props => props.theme.fonts.primary};
    font-weight: normal;
  }

  .type-large {
    font-size: ${props => props.theme.type.largeMobile};
    line-height: ${props => props.theme.lineHeights.largeMobile};
    letter-spacing: ${props => props.theme.letterSpacing.largeMobile};
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 800;

    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.type.large};
      line-height: ${props => props.theme.lineHeights.large};
      letter-spacing: ${props => props.theme.letterSpacing.large};
    }
  }

  .intro, .type-intro {
    font-size: ${props => props.theme.type.paragraph.intro};
    line-height: ${props => props.theme.lineHeights.paragraph.intro};
    color: ${props => props.theme.colors.darkBlue};
    letter-spacing: ${props => props.theme.letterSpacing.paragraph};
    font-family: ${props => props.theme.fonts.primary};
    font-weight: normal;
  }

  .type-label-large {
    font-size: ${props => props.theme.type.label.large};
    line-height: ${props => props.theme.lineHeights.label.large};
    color: ${props => props.theme.colors.darkBlue};
    letter-spacing: ${props => props.theme.letterSpacing.paragraph};
    font-weight: 900;
    font-family: ${props => props.theme.fonts.primary};
  }

  .label-small, .type-label-small {
    font-size: ${props => props.theme.type.label.small};
    line-height: ${props => props.theme.lineHeights.label.small};
    color: ${props => props.theme.colors.darkBlue};
    letter-spacing: ${props => props.theme.letterSpacing.paragraph};
    font-weight: 900;
    font-family: ${props => props.theme.fonts.primary};
  }

  .caption, .type-caption {
    font-size: ${props => props.theme.type.caption};
    line-height: ${props => props.theme.lineHeights.caption.default};
    color: ${props => props.theme.colors.darkBlue};
    letter-spacing: ${props => props.theme.letterSpacing.paragraph};
    font-family: ${props => props.theme.fonts.primary};
    font-weight: normal;

    &-tight {
      line-height: ${props => props.theme.lineHeights.caption.tight};
      font-size: ${props => props.theme.type.caption};
      color: ${props => props.theme.colors.darkBlue};
      letter-spacing: ${props => props.theme.letterSpacing.paragraph};
      font-family: ${props => props.theme.fonts.primary};
      font-weight: normal;
    }
  }

  .type-xs {
    font-size: ${props => props.theme.type.paragraph.xs};
    line-height: ${props => props.theme.lineHeights.paragraph.xs};
    font-family: ${props => props.theme.fonts.primary};
    font-weight: normal;
  }

  .type-extra-small {
    font-size: ${props => props.theme.type.paragraph.xs};
    line-height: ${props => props.theme.lineHeights.paragraph.xs};
    color: ${props => props.theme.colors.grey1};
    font-family: ${props => props.theme.fonts.primary};
    font-weight: normal;
  }

  .color {
    &-darkBlue {
      color: ${props => props.theme.colors.darkBlue};
    }

    &-vmfBlue {
      color: ${props => props.theme.colors.vmfBlue};
    }

    &-vmfBlueLight {
      color: ${props => props.theme.colors.vmfBlueLight};
    }

    &-babyBlueTint {
      color: ${props => props.theme.colors.babyBlueTint};
    }
    
    &-babyBlue {
      color: ${props => props.theme.colors.babyBlue};
    }
    
    &-vmfGreen {
      color: ${props => props.theme.colors.vmfGreen};
    }
    
    &-vmfGreen2 {
      color: ${props => props.theme.colors.vmfGreen2};
    }

    &-vmfRed {
      color: ${props => props.theme.colors.vmfRed};
    }
    
    &-grey1 {
      color: ${props => props.theme.colors.grey1};
    }
    
    &-grey2 {
      color: ${props => props.theme.colors.grey2};
    }
    
    &-grey3 {
      color: ${props => props.theme.colors.grey3};
    }
    
    &-grey4 {
      color: ${props => props.theme.colors.grey4};
    }
        
    &-grey5 {
      color: ${props => props.theme.colors.grey5};
    }
        
    &-grey6 {
      color: ${props => props.theme.colors.grey6};
    }
        
    &-grey7 {
      color: ${props => props.theme.colors.grey7};
    }
        
    &-white {
      color: ${props => props.theme.colors.white};
    }
        
    &-black {
      color: ${props => props.theme.colors.black};
    }
  }

  fieldset[disabled] {
    opacity: .75;
  }

  section[class*="block-"] {
    max-width: 100vw;
    overflow: hidden;
  }
  
  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  label.input-dropdown {
      &.open {
        .input-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          z-index: 100;
        }
      }

      .input-label {
        z-index: 99 !important;
      }

      .input-dropdown_icon {
        z-index: -1;
      }

      .input_wrapper {
        z-index: 100 !important;

        input {
          background: transparent;
          position: relative;
          z-index: 101;
        }
        
        .values {
          z-index: 100 !important;
        }
      }
    }

    label {
      .input-label {
        z-index: 1;
      }
    }

  .input-dropdown_icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    height: 1em;
    width: 1em;
    transform: translateY(-50%);
    color: ${props => props.theme.colors.darkBlue};
  }

  .desktop-only {
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      display: none;
    }
  }

  .mobile-only {
    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      display: none;
    }
  }

  .display {
    &-inline {
      display: inline;

      &-block {
        display: inline-block;
      }
    }
    
    &-none {
      display: none;

      &-mobile {
        @media (max-width: ${props => props.theme.breakpoints.tablet}) {
          display: none;
        }
      }

      &-tablet {
        @media (min-width: ${props => props.theme.breakpoints.tablet}) and (max-width: ${props => props.theme.breakpoints.desktop}) {
          display: none;
        }
      }

      &-desktop {
        @media (min-width: ${props => props.theme.breakpoints.desktop}) {
          display: none;
        }
      }
    }
  }

  .margin {
    &-top {
      &-auto {
        margin-top: auto
      }

      &-1 {
        margin-top: .25rem;
      }

      &-2 {
        margin-top: .5rem;
      }

      &-3 {
        margin-top: .75rem;
      }

      &-4 {
        margin-top: 1rem;
      }

      &-6 {
        margin-top: 1.5rem;
      }

      &-8 {
        margin-top: 2rem;
      }

      &-12 {
        margin-top: 3rem;
      }

      &-16 {
        margin-top: 4rem;
      }
    }

    &-right {
      &-auto {
        margin-right: auto
      }

      &-1 {
        margin-right: .25rem;
      }

      &-2 {
        margin-right: .5rem;
      }

      &-3 {
        margin-right: .75rem;
      }

      &-4 {
        margin-right: 1rem;
      }

      &-6 {
        margin-right: 1.5rem;
      }

      &-8 {
        margin-right: 2rem;
      }
      
      &-12 {
        margin-right: 3rem;
      }

      &-16 {
        margin-right: 4rem;
      }
    }

    &-bottom {
      &-auto {
        margin-bottom: auto
      }

      &-1 {
        margin-bottom: .25rem;
      }

      &-2 {
        margin-bottom: .5rem;
      }

      &-3 {
        margin-bottom: .75rem;
      }

      &-4 {
        margin-bottom: 1rem;
      }

      &-6 {
        margin-bottom: 1.5rem;
      }

      &-8 {
        margin-bottom: 2rem;
      }
      
      &-12 {
        margin-bottom: 3rem;
      }

      &-16 {
        margin-bottom: 4rem;
      }
    }

    &-left {
      &-auto {
        margin-left: auto
      }

      &-1 {
        margin-left: .25rem;
      }

      &-2 {
        margin-left: .5rem;
      }

      &-3 {
        margin-left: .75rem;
      }

      &-4 {
        margin-left: 1rem;
      }

      &-6 {
        margin-left: 1.5rem;
      }

      &-8 {
        margin-left: 2rem;
      }
      
      &-12 {
        margin-left: 3rem;
      }

      &-16 {
        margin-left: 4rem;
      }
    }
  }

  .no {
    &-decoration {
      text-decoration: none;
    }

    &-margin {
      margin: 0;

      &-top {
        margin-top: 0;
      }

      &-right {
        margin-right: 0;
      }

      &-bottom {
        margin-bottom: 0;
      }

      &-left {
        margin-left: 0;
      }
    }
  }

  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }

  a {
    &.underline {
      text-decoration: none;
      border-bottom: solid 2px currentColor;
    }
  }

  /* TODO: Delete placeholder styles */
  .placeholder-block {
    min-height: 8rem;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    text-align: center;
  }

  .placeholder-block a {
    color: #4e5255;
    margin-bottom: 1rem;
  }

  .placeholder-block .form {
    color: #ff0000;
  }

  .placeholder-block form {
    width: 100%;
    max-width: 24rem;
    padding: 0;
    margin: 0;
  }
  .placeholder-block form fieldset {
    border: none;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .placeholder-block form .input-group {
    margin-bottom: 0.5rem;
  }
  .placeholder-block form .input-group label {
    display: block;
    margin-right: 0.5rem;
  }
  .placeholder-block form .input-group input {
    display: block;
    width: 100%;
    margin-right: 0.5rem;
  }
  .placeholder-block form .input-group .error-message {
    color: #ff0000;
    font-size: 0.75rem;
  }
  .placeholder-block form .submit-button {
    display: block;
    margin: 1rem 0;
    width: 100%;
  }
  .placeholder-block td {
    padding: 0.5rem;
  }
`;

export default GlobalStyles;
