import styled from 'styled-components';

export default styled.section`
    display: flex;
    height: 100%;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;

    a {
        display: block;
        margin-right: 1.1875rem;

        svg {
           width: 1.5rem;
           height: 1.5rem;
        }
    }
`;