import React from 'react';
import Styles from './FooterPromoBackLink.styled';
import createGTMClass from '../../util/createGTMClass';
import Container from '../Shared/Container';
import ArrowIcon from '../Shared/ArrowIcon';

const FooterPromoBackLink = ({
  isPromoPage,
  isPPCPage,
  isHugDiscontinuedPage,
  isTvaDiscontinuedPage,
  ...props
}) => {
  return (
    <Styles
      isPromoPage={isPromoPage}
      isPPCPage={isPPCPage}
      isHugDiscontinuedPage={isHugDiscontinuedPage}
      isTvaDiscontinuedPage={isTvaDiscontinuedPage}
    >
      <Container>
        <section className='back_link'>
          <button
            onClick={props.handleBackToVMFClick}
            className={createGTMClass('vmf', 'footer', 'text')}
          >
            {!isPromoPage && <ArrowIcon />}
            {!isPromoPage && ' '}
            {isPPCPage ||
            isPromoPage ||
            isHugDiscontinuedPage ||
            isTvaDiscontinuedPage
              ? 'Go to VMF.com'
              : 'Back to VMF.com'}
          </button>
        </section>
      </Container>
    </Styles>
  );
};

export default FooterPromoBackLink;
