import React from 'react';

export default () => (
  <svg
    className='close'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.9615 6.70711C19.352 6.31658 19.352 5.68342 18.9615 5.29289C18.571 4.90237 17.9378 4.90237 17.5473 5.29289L12.2544 10.5858L6.9615 5.29289C6.57098 4.90237 5.93781 4.90237 5.54729 5.29289C5.15676 5.68342 5.15676 6.31658 5.54729 6.70711L10.8402 12L5.54729 17.2929C5.15676 17.6834 5.15676 18.3166 5.54729 18.7071C5.93781 19.0976 6.57098 19.0976 6.9615 18.7071L12.2544 13.4142L17.5473 18.7071C17.9378 19.0976 18.571 19.0976 18.9615 18.7071C19.352 18.3166 19.352 17.6834 18.9615 17.2929L13.6686 12L18.9615 6.70711Z'
      fill='#004B8C'
    />
  </svg>
);
