import styled from 'styled-components';

export default styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .logo {
    padding: 0.5rem;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      padding-left: 0.8125rem;
    }

    a {
      display: block;

      p {
        position: absolute;
        left: -9999999999px;
      }
    }

    svg {
      display: block;

      path {
        transition: all 0.2s ease-in-out;
        fill: ${props => (props.isDark ? props.theme.colors.white : null)};
      }
    }
  }

  .login-link {
    padding-right: 1.2813rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: ${props => props.theme.colors.darkBlue};
    }
  }
`;
