import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '../../components/Alert/Alert';
import { useAppContext, closeAlert } from '../../contexts/AppContext';
import alertOptions from '../../data/alertOptions.json';

const Alerts = () => {
  const [{ alerts }, dispatch] = useAppContext();
  const closedAlertsString = sessionStorage.getItem('vmfAlertsClosed') || '';
  const closedAlertsArr = closedAlertsString.split(';');
  const filteredAlerts =
    alerts &&
    alerts.filter(({ pageLink }) => closedAlertsArr.indexOf(pageLink) === -1);
  const [modifiedAlerts, setModifiedAlerts] = useState(filteredAlerts);

  const handleAlertClose = id => () => {
    closeAlert(dispatch, id);
  };

  useEffect(() => {
    if (filteredAlerts) {
      setModifiedAlerts(
        filteredAlerts.map((alert, i) => ({
          ...alert,
          background:
            i % 2 === 0 && alerts.length !== 1 ? 'vmfBlue' : 'darkBlue',
        }))
      );
    }
  }, []);

  return (
    <div className='alerts'>
      {modifiedAlerts &&
        modifiedAlerts.map(
          (
            {
              pageLink,
              bannerText,
              bannerLinkText,
              background,
              alertOptions: alertOption,
              modalHeader,
              modalContent,
            },
            i
          ) => (
            <Alert
              key={pageLink}
              text={bannerText}
              linkText={bannerLinkText}
              linkUrl={pageLink}
              onClose={handleAlertClose(pageLink)}
              id={pageLink}
              background={background}
              sourcedPage={alertOption === alertOptions['banner-and-webpage']}
              modalHeader={modalHeader}
              modalContent={modalContent}
              alertOption={alertOption}
            />
          )
        )}
    </div>
  );
};

Alerts.propTypes = {};

export default Alerts;
