import styled from 'styled-components';
import { breakpoints } from '../../styles/variables';

const SubNavStyles = styled.div`
  margin: 0;
  padding: 0;
  background: ${props => props.background || props.theme.colors.vmfBlue};
  position: fixed;
  width: 100%;
  top: ${props => props.subNavTop}px;
  transform: translateY(
    ${props => (props.scrollDirection === 'up' ? 0 : -props.subNavTop)}px
  );
  z-index: 9998;
  transition: all 0.4s ease-in-out;

  .subnav-wrapper {
    position: relative;

    .subnav-mobile {
      display: none;
    }

    .subnav-list {
      margin: 0;
      display: flex;
      padding: 0.5rem;
      justify-content: center;
      position: relative;
      z-index: 10;

      .subnav-list-item {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;

        .subnav-list-link {
          color: ${props => props.theme.colors.grey1};
          text-decoration: none;
          line-height: ${props => props.theme.lineHeights.caption.default};
          font-size: ${props => props.theme.type.caption};
          margin: 0 1rem;

          padding: 0.375rem 0.75rem;
          border-radius: 1.5em;

          @media (min-width: ${props => props.theme.breakpoints.desktop}) {
            color: ${props => props.theme.colors.white};
          }
        }
      }
    }
  }

  .indicator {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    position: absolute;
    left: 0;
    top: 0.5rem;
    transform: translateX(50%);
    height: calc(100% - 1rem);
  }

  @media screen and (max-width: ${breakpoints.desktop}) {
    .subnav-wrapper {
      .subnav-mobile {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 100;
        padding: 0.6875rem;
        background: ${props => props.background || props.theme.colors.vmfBlue};

        .subnav-mobile-dropdown {
          flex: 0 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.41rem 0.67rem;
          border-radius: 1em;
          outline: none;
          border: solid 1px transparent;
          color: #ffffff;
          text-transform: uppercase;
          font-size: ${props => props.theme.type.label.large};
          letter-spacing: ${props => props.theme.letterSpacing.label};
          cursor: pointer;
          background-color: rgba(255, 255, 255, 0.25);

          &:hover,
          &:active,
          &:focus {
            border: solid 1px ${props => props.theme.colors.white};
            background: transparent;
          }

          svg {
            margin-left: 0.5625rem;
            transition: all 0.4s ease-in-out;
          }

          &.open {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }

      .subnav-list {
        box-sizing: border-box;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        width: 100%;
        transition: all 0.4s ease-in-out;
        background: ${props => props.theme.colors.white};
        padding: 1.375rem;

        &.closed {
          transform: translateY(-100%);
        }

        &.open {
          box-shadow: ${props => props.theme.shadows.high};
        }

        .subnav-list-item {
          display: flex;
          justify-content: center;
          margin: 0;

          .subnav-list-link {
            color: ${props => props.theme.colors.grey1};
            padding: 0.49rem;
            box-sizing: border-box;
          }
        }
      }
    }
  }
`;

export default SubNavStyles;
