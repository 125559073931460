import React from 'react';
import Styles from './styled';
import { NavLink } from 'react-router-dom';
import noop from '../../../../util/noop';
import VMFLogo from '../../../VMFLogo/VMFLogo';
import AvatarIcon from '../../../Shared/Icons/avatar';

export default ({
  closeMenu = noop,
  isDark = false,
  isLandingPage = false,
  children,
}) => {
  return (
    <Styles isDark={isDark}>
      <div className='logo'>
        <NavLink to='/'>
          <VMFLogo style='color' />
          <p>Home</p>
        </NavLink>
      </div>
      <a
        href='#energyright_hero'
        rel='noopener nofollow'
        aria-label='Log In'
        className='login-link'
      >
        <AvatarIcon />
      </a>
    </Styles>
  );
};
