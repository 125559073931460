import React from 'react';
import Styles from './styled';
import { NavLink } from 'react-router-dom';
import useGTM from '../../../../hooks/useGTM';
import noop from '../../../../util/noop';
import VMFLogo from '../../../VMFLogo/VMFLogo';

export default ({
  closeMenu = noop,
  isDark = false,
  isLandingPage = false,
  children,
}) => {
  const [triggerGTMEvent] = useGTM();

  return (
    <Styles isDark={isDark}>
      <div className='logo'>
        <NavLink to='/'>
          <VMFLogo style='color' />
          <p>Home</p>
        </NavLink>
      </div>
      {children}
    </Styles>
  );
};
