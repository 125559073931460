import React from 'react';

function CloseIcon({ color = '#FFFFFF' }) {
  return (
    <svg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>Close</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.98271 8.18692L11.3799 5.78975C11.5995 5.57008 11.9557 5.57008 12.1754 5.78975C12.395 6.00942 12.395 6.36558 12.1754 6.58525L9.77821 8.98241L12.1757 11.3799C12.3953 11.5995 12.3953 11.9557 12.1757 12.1754C11.956 12.395 11.5998 12.395 11.3802 12.1754L8.98271 9.77791L6.58525 12.1754C6.36558 12.395 6.00942 12.395 5.78975 12.1754C5.57008 11.9557 5.57008 11.5995 5.78975 11.3799L8.18722 8.98241L5.79005 6.58525C5.57038 6.36558 5.57038 6.00942 5.79005 5.78975C6.00972 5.57008 6.36588 5.57008 6.58555 5.78975L8.98271 8.18692Z'
        fill={color}
      />
    </svg>
  );
}

export default React.memo(CloseIcon);
