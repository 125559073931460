import React from 'react';
import Styles from './styled';
import { Link } from 'react-router-dom';
import noop from '../../../../util/noop';
import ScrollLink from '../../../ScrollLink';
import { getBaseEscoreApiUrl } from '../../../../util/config';

export default ({
  closeMenu = noop,
  phoneNumber = '1-888-893-8792',
  email = 'EnergyRightLoans@vmf.com',
}) => {
  return (
    <Styles>
      <section className='menu-section primary'>
        <ul>
          <li className='menu-item'>
            <ScrollLink className='type-intro' href='#overview'>
              Overview
            </ScrollLink>
          </li>
          <li className='menu-item'>
            <ScrollLink className='type-intro' href='#what-we-finance'>
              What we finance
            </ScrollLink>
          </li>
          <li className='menu-item'>
            <ScrollLink className='type-intro' href='#how-to-apply'>
              How to apply
            </ScrollLink>
          </li>
          <li className='menu-item'>
            <ScrollLink className='type-intro' href='#faqs'>
              FAQs
            </ScrollLink>
          </li>
          <li className='menu-item'>
            <ScrollLink className='type-intro' href='#about-us'>
              About us
            </ScrollLink>
          </li>
          <li className='menu-item'>
            <a
              className='type-intro'
              href={`${getBaseEscoreApiUrl()}/Account/Login`}
            >
              Sign in
            </a>
          </li>
        </ul>
      </section>
      <hr />
      <section className='menu-section secondary'>
        <div className='type-p'>
          Questions? Call{' '}
          <a href={`tel:${phoneNumber.replace(/-/gim, '')}`}>{phoneNumber}</a>
          &nbsp;or email&nbsp;<a href={`mailto:${email}`}>{email}</a>
        </div>
        <Link className='back type-p' to='/' onClick={closeMenu}>
          Visit VMF.com
        </Link>
      </section>
    </Styles>
  );
};
