import { useEffect } from 'react';
import PropTypes from 'prop-types';
import noop from '../../../util/noop';
import { useAppContext } from '../../../contexts/AppContext';

const Modal = ({ onClose = noop, fitContent = false, children }) => {
  const [state, dispatch] = useAppContext();

  useEffect(() => {
    dispatch({
      type: 'OPEN_MODAL',
      onClose,
      children,
      fitContent,
    });

    return () => {
      dispatch({
        type: 'CLOSE_MODAL',
      });
    };
  }, []);

  return null;
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string.isRequired,
};

export default Modal;
