import React from 'react';
import { useLocation } from 'react-router-dom';
import useGTM from '../../../../hooks/useGTM';
import { getBaseHugUrl } from '../../../../util/config';
import ButtonLink from '../../../Shared/ButtonLink';
import PhoneIcon from '../../../Shared/Icons/Phone';
import Styles from '../DesktopHeader/styled';
import createGTMClass from '../../../../util/createGTMClass';

export default ({ isContractor = false }) => {
  const [triggerGTMEvent] = useGTM();
  const location = useLocation();

  return (
    <Styles>
      <section className='contact'>
        {isContractor && (
          <a
            href='tel:888-893-8792'
            className={createGTMClass('phone', 'header', 'text')}
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'B2B Navigation',
              category: 'contact_us',
              action: 'phone',
              label: 'click_to_call',
            })}
          >
            <PhoneIcon />
            888-893-8792
          </a>
        )}
      </section>
      <section className='ctas'>
        <ButtonLink
          to={
            isContractor
              ? '/contractor-enroll/contact-us/'
              : `${getBaseHugUrl()}/home-upgrade-loan/Auth/Login`
          }
          appearance='light'
          hoverAppearance='dark'
          size='small'
          isExternal={!isContractor}
          className={
            isContractor
              ? createGTMClass('contact', 'header', 'button', [
                  'button-link-vmf-blue',
                ])
              : createGTMClass('sign_in', 'header', 'button', [
                  'button-link-vmf-blue',
                ])
          }
          onClick={
            !isContractor
              ? triggerGTMEvent({
                  name: 'vmf.cta_click',
                  location: 'B2C Navigation',
                  category: 'account_sign_in',
                  action: 'nav',
                  label: 'sign_in',
                })
              : () => {}
          }
        >
          {isContractor ? 'Contact us' : 'Sign in'}
        </ButtonLink>
        {!location?.pathname?.match(
          /^\/home-upgrade-loan\/availability\/?$/gim
        ) && (
          <ButtonLink
            to={
              isContractor
                ? 'https://vmf.energydatametrics.com/Contractor/Signin.aspx'
                : '/home-upgrade-loan/availability'
            }
            appearance='dark'
            size='small'
            isExternal={isContractor}
            className={
              isContractor
                ? createGTMClass('sign_in', 'header', 'button')
                : createGTMClass('apply', 'hug_header', 'button')
            }
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: isContractor ? 'B2B Navigation' : 'B2C Navigation',
              category: isContractor ? 'account_sign_in' : 'loan_application',
              action: isContractor ? 'text' : 'nav',
              label: isContractor ? 'sign_in' : 'apply_today',
            })}
          >
            {isContractor ? 'Sign in' : 'Apply today'}
          </ButtonLink>
        )}
      </section>
    </Styles>
  );
};
