import styled from 'styled-components';

export default styled.section`
  display: flex;
  flex-direction: row;

  .logo {
      padding: 0.8125rem 0;
}
`;
