import styled from 'styled-components';

export default styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  padding: 0 ${props => props.theme.padding.inner};
  box-sizing: border-box;
  transition: all 0.4s ease-in-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-name: ${props => (props.open ? 'modalEnter' : 'modalExit')};

  .inner {
    width: ${props => props.fitContent ? 'auto' : `calc(100% - (${props.theme.padding.inner} * 2));`};
    max-width: ${props => props.theme.maxWidths.modal};
    max-height: calc(100vh - (${props => props.theme.padding.inner} * 2));
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: calc(
        (100vh - (${props => props.theme.padding.inner} * 2)) * 1.45
      );
    }

    figure {
      overflow: hidden;
    }
  }

  .backdrop {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85);
    top: 0;
    left: 0;
    z-index: -1;
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      background: transparent;
      border: none;
      margin-right: -0.5rem;
      cursor: pointer;

      svg {
        path {
          fill: ${props => props.theme.colors.white};
        }
      }
    }
  }

  .content {
    background: ${props => props.theme.colors.white};
    min-height: 50%;
    max-height: calc(100vh - ${props => props.theme.padding.inner});
    overflow: auto;

    iframe {
      width: 100%;
    }
  }

  @keyframes modalEnter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes modalExit {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
