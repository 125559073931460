import { useMemo } from 'react';
import { useLocation } from 'react-router';

const hugContractorPages = [
  '/contractor-enroll/',
  '/contractor-enroll/contact-us/',
  '/contractor-enroll/thank-you/',
];

const hugHomeownerPages = ['/home-upgrade-loan/'];

export default () => {
  const location = useLocation();

  const cleanPathName = useMemo(
    () =>
      location.pathname.slice(-1) !== '/'
        ? `${location.pathname}/`
        : location.pathname,
    [location.pathname]
  );

  const isHugContractorPage = useMemo(
    () => hugContractorPages.includes(cleanPathName),
    [cleanPathName]
  );

  const isHugHomeownerPage = useMemo(
    () => hugHomeownerPages.includes(cleanPathName),
    [cleanPathName]
  );

  const isHugPage = useMemo(
    () => isHugContractorPage || isHugHomeownerPage,
    [isHugContractorPage, isHugHomeownerPage]
  );

  const isPPCPage = useMemo(
    () => !!cleanPathName.match(/^\/pages\/.*/gim),
    [cleanPathName]
  );

  const isPromoPage = useMemo(
    () => !!cleanPathName.match(/^\/promo\/.*/gim),
    [cleanPathName]
  );

  const isBusCardPage = useMemo(
    () => !!cleanPathName.match('direct-lending/team/'),
    [cleanPathName]
  );

  const isHugDiscontinuedPage = useMemo(
    () => !!cleanPathName.match('/discontinued-home-upgrade-loan/'),
    [cleanPathName]
  );

  const isTvaDiscontinuedPage = useMemo(
    () => !!cleanPathName.match('/discontinued-tva-energyright-loan/'),
    [cleanPathName]
  );

  return {
    isHugContractorPage,
    isHugHomeownerPage,
    isHugPage,
    isPPCPage,
    isPromoPage,
    isBusCardPage,
    isHugDiscontinuedPage,
    isTvaDiscontinuedPage,
  };
};
