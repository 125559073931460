import React, { useMemo } from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Providers from './providers';
import Promise from 'core-js/es/promise';
import 'core-js/es/weak-set';
import 'core-js/es/symbol';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ModalContainer from './components/ModalContainer/ModalContainer';
import SubNav from './components/SubNav/SubNav';
import useResizeObserver from './hooks/useResizeObserver';

// Hacky way to force function into bundle.
// FIX THISSS
import { getPageContent } from './services/dpc';
import { useAppContext } from './contexts/AppContext';

const App = ({ after: After, data, routes }) => {
  const [headerRef, headerEntry] = useResizeObserver();
  const [footerRef, footerEntry] = useResizeObserver();

  // THIS CANNOT BE INSIDE AN EFFECT, SEE VMFPW-1624 FOR BUG
  const [{ modal }] = useAppContext();

  if (modal && document.body.style.overflow !== 'hidden') {
    document.body.style.overflow = 'hidden';
  } else if (!modal && document.body.style.overflow !== 'auto') {
    document.body.style.overflow = 'auto';
  }
  // END WARNING

  const { headerHeight, footerHeight } = useMemo(
    () => ({
      headerHeight: headerEntry?.contentRect?.height,
      footerHeight: footerEntry?.contentRect?.height,
    }),
    [headerEntry, footerEntry]
  );

  const bodyMinHeight = useMemo(
    () => `calc(100vh - ${headerHeight + footerHeight}px)`,
    [headerHeight, footerHeight]
  );

  return (
    <>
      <div ref={headerRef}>
        <Header />
        <SubNav />
      </div>
      <section style={{ minHeight: bodyMinHeight }}>
        <After data={data} routes={routes} transitionBehavior='instant' />
      </section>
      <div ref={footerRef}>
        <Footer />
      </div>
      <ModalContainer />
    </>
  );
};

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/serviceworker.js');
  });
}

const asyncFiles = [];

if (!window.fetch) {
  asyncFiles.push(import(/* webpackPreload: true */ './polyfill'));
}

asyncFiles.push(import(/* webpackPreload: true */ './styles/fonts.css'));

Promise.all(asyncFiles).then(async () => {
  const { After, ensureReady, getSerializedData } = await import(
    /* webpackPrefetch: true */

    '@jaredpalmer/after'
  );
  const { default: routes } = await import(
    /* webpackPrefetch: true */ './routes'
  );

  ensureReady(routes).then(data => {
    const serverAppState = getSerializedData('preloaded_state');

    return hydrate(
      <BrowserRouter>
        <Providers serverAppState={serverAppState}>
          <App after={After} data={data} routes={routes} />
        </Providers>
      </BrowserRouter>,
      document.getElementById('root')
    );
  });

  if (module.hot) {
    module.hot.accept();
  }
});
