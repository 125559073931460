import React from 'react';
import noop from '../../util/noop';

export default ({
  href = '',
  onClick = noop,
  children,
  className = '',
  ...props
}) => {
  const handleLinkClick = e => {
    e.preventDefault();
    window.history.pushState('', document.title, e.target.href);
    const elem = document.getElementById(href?.replace('#', ''));
    if (elem) elem.scrollIntoView({ behavior: 'smooth' });
    onClick();
  };

  return (
    <a className={className} href={href} onClick={handleLinkClick} {...props}>
      {children}
    </a>
  );
};
