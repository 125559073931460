export default [
  '/',
  '/home-loans/',
  '/portfolio-home-loan/',
  '/land-home-mortgage/',
  '/biweekly-mortgage/',
  '/fresh-start-home-loan/',
  '/home-upgrade-loan/',
  '/commercial-lending/',
  '/home-buying/',
  '/calculators/mortgage-calculator/',
  '/calculators/early-payoff-calculator/',
  '/calculators/amortization-calculator/',
  '/calculators/extra-monthly-principal/',
  '/calculators/biweekly-extra-principal/',
  '/about/',
  '/offline/',
];
