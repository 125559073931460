import React from 'react';

export default () => (
  <svg viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 3.5c5 0 9 4 9 9s-4 9-9 9-9-4-9-9 4-9 9-9zm0-2c-6.1 0-11 4.9-11 11s4.9 11 11 11 11-4.9 11-11-4.9-11-11-11z'
      fill='#0E2551'
    />
    <path
      d='M12 7.5c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5 1.1-2.5 2.5-2.5zm0-2c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5-2-4.5-4.5-4.5z'
      fill='#0E2551'
    />
    <path
      d='M12 14.5c2.9 0 5.3 2.1 5.9 4.8-1.7 1.4-3.7 2.2-5.9 2.2s-4.2-.8-5.9-2.2c.6-2.7 3-4.8 5.9-4.8zm0-2c-4.3 0-7.7 3.3-8 7.6 2 2.1 4.8 3.4 8 3.4s6-1.3 8-3.4c-.3-4.3-3.7-7.6-8-7.6z'
      fill='#0E2551'
    />
  </svg>
);
