import React from 'react';
import PropTypes from 'prop-types';
const defaultSources = [
  {
    media: '(max-width: 425px)',
    params: '?w=425',
  },
  {
    media: '(max-width: 768px)',
    params: '?w=768',
  },
  {
    media: '(max-width: 1440px)',
    params: '?w=1440',
  },
];

const Picture = ({
  src = '',
  alt = '',
  isResponsive = false,
  sources = defaultSources,
  className = '',
  children = null,
  width = '',
  height = '',
  ...props
}) => (
  <picture className={className} {...props}>
    {isResponsive &&
      sources.map(({ media = '', params = '', srcSet = '' }) => (
        <source
          key={params}
          media={media}
          srcSet={`${srcSet || src}${params}`}
        />
      ))}

    <img width={width} height={height} src={src} alt={alt} />

    {children}
  </picture>
);

Picture.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  isResponsive: PropTypes.bool,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      media: PropTypes.string.isRequired,
      params: PropTypes.string.isRequired,
      srcSet: PropTypes.string,
    })
  ),
  className: PropTypes.string,
};

export default Picture;
