import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { AppProvider } from './contexts/AppContext';
import GlobalStyles from './styles/global';
import theme from './styles/theme';

function Providers({ children, serverAppState }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppProvider serverAppState={serverAppState}>{children}</AppProvider>
    </ThemeProvider>
  );
}

Providers.propTypes = {
  children: PropTypes.node,
  serverAppState: PropTypes.object,
};

export default Providers;
