import axios from 'axios';
import { getBaseApiUrl, getRequestHeaders } from '../util/config';

export const getPageContent = async (slug = '/') =>
  await axios.get(
    `${getBaseApiUrl()}/Contentful/dpc?pageUrl=${encodeURIComponent(slug)}`,
    {
      headers: getRequestHeaders(),
    }
  );
