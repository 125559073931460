class CustomUrl {
  constructor(configOrFullPath) {
    if (typeof configOrFullPath === 'object') {
      const { url, query } = configOrFullPath;
      this.url = url;

      if (typeof query === 'string') {
        this.query = this.convertQueryStringToObject(query);
      } else if (typeof query === 'object') {
        this.query = query;
      } else {
        this.query = {};
      }
    } else if (typeof configOrFullPath === 'string') {
      const [u, q] = configOrFullPath.split('?');
      this.url = u;
      this.query = this.convertQueryStringToObject(q);
    }
  }

  convertQueryStringToObject(queryString = '') {
    if (queryString) {
      const parseString = queryString.includes('?')
        ? queryString.split('?')[1]
        : queryString;

      return parseString?.split('&')?.reduce((p, c) => {
        const [key, value] = c.split('=');
        if (key && value) {
          p[key] = value;
        }

        return p;
      }, {});
    }

    return {};
  }

  convertQueryObjectToString(queryObject = {}) {
    if (queryObject && Object.keys(queryObject).length) {
      const output = Object.keys(queryObject)?.reduce((p, c, i, a) => {
        p += `${c}=${queryObject[c]}`;
        if (i < a.length - 1) {
          p += '&';
        }

        return p;
      }, '?');

      return output || '';
    }

    return '';
  }

  addToPath() {}
  removeFromPath() {}

  addQueryParam(param, value) {
    this.query[param] = value;
  }

  removeQueryParam(...paramValues) {
    for (let p in paramValues) {
      if (this.query?.[paramValues[p]]) {
        delete this.query[paramValues[p]];
      }
    }
  }

  joinQuery(...queries) {
    for (let q in queries) {
      let query = queries[q];
      if (typeof query === 'string') {
        query = this.convertQueryStringToObject(query);
      }

      Object.keys(query).forEach(v => {
        this.query[v] = query[v];
      });
    }
  }

  get fullUrl() {
    const cleanQuery = Object.entries(this.query).reduce((p, [k, v]) => {
      if (v) p[k] = v;
      return p;
    }, {});
    return [this.url, this.convertQueryObjectToString(cleanQuery)].join('');
  }

  get queryParams() {
    return this.query;
  }
}

export default CustomUrl;
