import React from 'react';
import Styles from './styled';
import AvatarIcon from '../../../Shared/Icons/avatar';
import useGTM from '../../../../hooks/useGTM';
import { getBaseHugUrl } from '../../../../util/config';
import createGTMClass from '../../../../util/createGTMClass';

export default ({ isContractor = false }) => {
  const [triggerGTMEvent] = useGTM();

  return (
    <Styles>
      <a
        href={
          isContractor
            ? 'https://vmf.energydatametrics.com/Contractor/Signin.aspx'
            : `${getBaseHugUrl()}/home-upgrade-loan/Auth/Login`
        }
        rel='noopener nofollow'
        aria-label='Log In'
        className={createGTMClass('sign_in', 'header', 'button')}
        onClick={
          isContractor
            ? triggerGTMEvent({
                name: 'vmf.cta_click',
                location: 'B2B Navigation',
                category: 'account_sign_in',
                action: 'text',
                label: 'sign_in',
              })
            : () => {}
        }
      >
        <AvatarIcon />
      </a>
    </Styles>
  );
};
