import React from 'react';
import Styles from './index.styled.js';
import { NavLink } from 'react-router-dom';
import TempVMFLogo from '../../../../../public/images/vmf-logo.svg';
import ButtonLink from '../../../Shared/ButtonLink.js';
import useGTM from '../../../../hooks/useGTM.js';
import { useAppContext } from '../../../../contexts/AppContext.js';

export default ({
  hideButtons = false,
  isDark = false,
  isLandingPage = false,
  children,
  hideNavigation = false,
}) => {
  const [{ headerLink }] = useAppContext();
  const [triggerGTMEvent] = useGTM();

  return (
    <Styles isDark={isDark}>
      <div className='logo'>
        <NavLink
          to='/'
          onClick={
            isLandingPage
              ? triggerGTMEvent({
                  name: 'vmf.cta_click',
                  location: landingPageLocations[window.location.pathname],
                  category: 'brand_evaluation',
                  action: 'logo',
                  label: 'vmf_homepage_exit',
                })
              : null
          }
        >
          <img width='150px' src={TempVMFLogo} />
          <p>Home</p>
        </NavLink>
      </div>
      {!hideNavigation && (
        <ul className='primary'>
          <li className='menu-item'>
            <NavLink to='/home-loans/'>Mortgages & Loans</NavLink>
          </li>
          <li className='menu-item'>
            <NavLink to='/home-buying/'>Home Buying</NavLink>
          </li>
          <li className='menu-item'>
            <NavLink to='/home-owning/'>Home Owning</NavLink>
          </li>
          <li className='menu-item'>
            <NavLink to='/calculators/mortgage-calculator/'>
              Calculators
            </NavLink>
          </li>
          <li className='menu-item'>
            <NavLink to='/resources/'>Resources</NavLink>
          </li>
          <li className='menu-item'>
            <NavLink to='/contact-us/'>Contact</NavLink>
          </li>
          <li className='menu-item'>
            <a
              onClick={triggerGTMEvent({
                name: 'vmf.cta_click',
                location: 'All Pages',
                category: 'job_application',
                action: 'nav',
                label: 'careers',
              })}
              href='https://careers.claytonhomes.com/job-search?division=Vanderbilt&skillArea=&keywords=&postalCode=&distance='
              target='_blank'
              rel='noreferrer noopener'
            >
              Careers
            </a>
          </li>
        </ul>
      )}
      {!hideButtons && (
        <div>
          <ButtonLink
            to={headerLink}
            appearance='dark'
            size='small'
            isExternal={true}
            newTab={true}
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'All Pages',
              category: 'loan_application',
              action: 'nav',
              label: 'apply',
            })}
          >
            Apply Today
          </ButtonLink>
        </div>
      )}
      {children}
    </Styles>
  );
};
