import React, { useState, useEffect } from 'react';
import ModalContainerStyles from './ModalContainer.styled';
import { useAppContext } from '../../contexts/AppContext';
import CloseIcon from '../Shared/Icons/close';

const ModalContainer = () => {
  const [{ modal }, dispatch] = useAppContext();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (modal) {
      setOpen(true);
    }
  }, [modal]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(e => {
      modal.onClose();
    }, 400);
  };

  if (!modal) {
    return null;
  }

  return (
    <ModalContainerStyles
      role='dialog'
      aria-modal='true'
      open={open}
      className='modal'
      fitContent={modal?.fitContent}
    >
      <div className='inner'>
        <header>
          <button type='button' aria-label='close' onClick={handleClose}>
            <CloseIcon />
          </button>
        </header>
        <section className='content'>{modal.children}</section>
      </div>
      <div className='backdrop' onClick={handleClose}></div>
    </ModalContainerStyles>
  );
};

export default ModalContainer;
