import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  cloneElement,
} from 'react';
import PropTypes from 'prop-types';
import HeaderStyles from './Header.styled';
import VMFLogo from '../VMFLogo/VMFLogo';
import { Link, NavLink, useLocation } from 'react-router-dom';
import MenuToggle from '../Shared/Icons/menuToggle';
import ButtonLink from '../Shared/ButtonLink';
import Alerts from '../Alerts/Alerts';
import { useAppContext, closeAlert } from '../../contexts/AppContext';
import useGTM from '../../hooks/useGTM';

import EnergyRightMobileHeader from './EnergyRight/MobileHeader';
import EnergyRightPreHeader from './EnergyRight/PreHeader';
import EnergyRightMobileMenu from './EnergyRight/MobileMenu';
import EnergyRightDesktopHeader from './EnergyRight/DesktopHeader';
import HUGMobileHeader from './HomeUpgradeLoan/MobileHeader';
import HUGPreHeader from './HomeUpgradeLoan/PreHeader';
import HUGMobileMenu from './HomeUpgradeLoan/MobileMenu';
import HUGDesktopHeader from './HomeUpgradeLoan/DesktopHeader';
import DirectLendingHeader from './DirectLending/DesktopHeader';
import DirectLendingMobile from './DirectLending/MobileHeader';
import DirectLendingMobileMenu from './DirectLending/MobileMenu';
import DefaultMobileHeader from './Default/MobileHeader';
import DefaultMobileMenu from './Default/MobileMenu';
import DefaultDesktopHeader from './Default/DesktopHeader';
import PPCDesktopHeader from './PPC/DesktopHeader';
import PPCMobileHeader from './PPC/MobileHeader';
import PromoLPTemplate from './PromoLPTemplate/DesktopHeader';
import BusCardTemplate from './BusCardTemplate/DesktopHeader';
import Contact from './Contact';
import theme from '../../styles/theme';
import { useTheme } from 'styled-components';

const landingPageLocations = {
  '/applynow/welcome/': 'Application Page 1',
  '/applynow/welcome': 'Application Page 1',
  '/applynow/welcome2/': 'Application Page 2',
  '/applynow/welcome2': 'Application Page 2',
};

const headerItems = {
  pagesRemoveButtons: [
    '/home-upgrade-loan/availability',
    '/home-upgrade-loan/availability/',
  ],
  hideNavigation: [
    '/applynow/welcome/',
    '/applynow/welcome',
    '/applynow/welcome2/',
    '/applynow/welcome2',
    '/home-upgrade-loan',
    '/home-upgrade-loan/',
    '/contractor-enroll/',
    '/contractor-enroll',
    '/ce/',
    '/ce',
    '/contractor-enroll/contact-us/',
    '/contractor-enroll/contact-us',
    '/contractor-enroll/thank-you/',
    '/contractor-enroll/thank-you',
  ],
  showContact: [
    '/applynow/welcome/',
    '/applynow/welcome',
    '/applynow/welcome2/',
    '/applynow/welcome2',
  ],
  darkHeader: ['/applynow/welcome2/', '/applynow/welcome2'],
  hideAlerts: [
    '/applynow/welcome/',
    '/applynow/welcome',
    '/applynow/welcome2/',
    '/applynow/welcome2',
    '/home-upgrade-loan',
    '/home-upgrade-loan/',
    '/contractor-enroll/',
    '/contractor-enroll',
    '/contractor-enroll/contact-us/',
    '/contractor-enroll/contact-us',
    '/contractor-enroll/thank-you/',
    '/contractor-enroll/thank-you',
    '/ce/',
    '/ce',
    '/ce/contact-us/',
    '/ce/contact-us',
    '/ce/thank-you/',
    '/ce/thank-you',
  ],
  landingPages: [
    '/applynow/welcome/',
    '/applynow/welcome',
    '/applynow/welcome2/',
    '/applynow/welcome2',
  ],
  homeUpgradeContractorPages: [
    '/contractor-enroll/',
    '/contractor-enroll',
    '/contractor-enroll/contact-us/',
    '/contractor-enroll/contact-us',
    '/contractor-enroll/thank-you/',
    '/contractor-enroll/thank-you',
    '/ce/',
    '/ce',
    '/ce/contact-us/',
    '/ce/contact-us',
    '/ce/thank-you/',
    '/ce/thank-you',
  ],
};

const defaultHUGContractor = {
  mobileHeader: (
    <DefaultMobileHeader>
      <HUGMobileHeader isContractor />
    </DefaultMobileHeader>
  ),
  mobileMenu: <HUGMobileMenu isContractor />,
  preHeader: <HUGPreHeader isContractor />,
  desktopHeader: (
    <DefaultDesktopHeader hideNavigation hideButtons>
      <HUGDesktopHeader isContractor />
    </DefaultDesktopHeader>
  ),
};

const defaultHUGHomeowner = {
  mobileHeader: (
    <DefaultMobileHeader>
      <HUGMobileHeader />
    </DefaultMobileHeader>
  ),
  mobileMenu: <HUGMobileMenu />,
  preHeader: <HUGPreHeader />,
  desktopHeader: (
    <DefaultDesktopHeader hideNavigation hideButtons>
      <HUGDesktopHeader />
    </DefaultDesktopHeader>
  ),
};

const homeLoanLight = {
  mobileHeader: (
    <DefaultMobileHeader isLandingPage>
      <Contact />
    </DefaultMobileHeader>
  ),
  mobileMenu: false,
  desktopHeader: (
    <DefaultDesktopHeader hideNavigation hideButtons isLandingPage>
      <Contact />
    </DefaultDesktopHeader>
  ),
  preHeader: false,
};

const homeLoanDark = {
  mobileHeader: (
    <DefaultMobileHeader isDark isLandingPage>
      <Contact isDark />
    </DefaultMobileHeader>
  ),
  mobileMenu: false,
  desktopHeader: (
    <DefaultDesktopHeader hideNavigation hideButtons isDark isLandingPage>
      <Contact isDark />
    </DefaultDesktopHeader>
  ),
  preHeader: false,
  isDark: true,
};

const energyRight = {
  mobileHeader: (
    <EnergyRightMobileHeader>
      <Contact isDark />
    </EnergyRightMobileHeader>
  ),
  mobileMenu: (
    <EnergyRightMobileMenu
      phoneNumber='1-888-893-8792'
      email='EnergyRightLoans@vmf.com'
    />
  ),
  desktopHeader: <EnergyRightDesktopHeader hideNavigation hideButtons />,
  preHeader: (
    <EnergyRightPreHeader
      phoneNumber='1-888-893-8792'
      email='EnergyRightLoans@vmf.com'
    />
  ),
};

const ppcHeader = {
  mobileHeader: (
    <DefaultMobileHeader headerLogoUrl='/?utm_medium=ppc'>
      <PPCMobileHeader />
    </DefaultMobileHeader>
  ),
  mobileMenu: false,
  desktopHeader: (
    <DefaultDesktopHeader
      hideNavigation
      hideButtons
      headerLogoUrl='/?utm_medium=ppc'
    >
      <PPCDesktopHeader />
    </DefaultDesktopHeader>
  ),
  preHeader: false,
  isDark: false,
};
const defaultDirectLending = {
  mobileHeader: <DirectLendingMobile />,
  mobileMenu: <DirectLendingMobileMenu />,
  preHeader: <Alerts />,
  desktopHeader: <DirectLendingHeader />,
};
const defaultPromoLPTemplate = {
  desktopHeader: <PromoLPTemplate />,
  mobileHeader: <PromoLPTemplate />,
};
const HugDiscontinuedTemplate = {
  desktopHeader: <PromoLPTemplate />,
  mobileHeader: <PromoLPTemplate />,
};

const defaultBusCardHeader = {
  desktopHeader: <BusCardTemplate hideNavigation />,
  mobileHeader: false,
};

const configs = {
  '/contractor-enroll/': defaultHUGContractor,
  '/contractor-enroll/contact-us/': defaultHUGContractor,
  '/contractor-enroll/thank-you/': defaultHUGContractor,
  '/ce/': defaultHUGContractor,
  '/ce/contact-us/': defaultHUGContractor,
  '/ce/thank-you/': defaultHUGContractor,
  '/home-upgrade-loan/': defaultHUGHomeowner,
  '/home-upgrade-loan/availability/': defaultHUGHomeowner,
  '/applynow/welcome/': homeLoanLight,
  '/applynow/welcome2/': homeLoanDark,
  '/pages': ppcHeader,
  '/direct-lending/': defaultDirectLending,
  '/tva-energyright-loan/': energyRight,
  '/promo': defaultPromoLPTemplate,
  '/direct-lending/team': defaultBusCardHeader,
  '/discontinued-home-upgrade-loan/': HugDiscontinuedTemplate,

  default: {
    mobileHeader: <DefaultMobileHeader />,
    mobileMenu: <DefaultMobileMenu />,
    desktopHeader: <DefaultDesktopHeader />,
    preHeader: <Alerts />,
  },
};

function Header() {
  const theme = useTheme();
  const [triggerGTMEvent] = useGTM();
  const [{ scrollDirection, headerHeight }, dispatch] = useAppContext();
  // Manage the nav state
  const [navOpen, setNavOpen] = useState(false);
  const [resizeTimeout, setResizeTimeout] = useState(false);
  const toggleMenu = () => setNavOpen(!navOpen);
  // Use close menu for when user clicks on current route
  const headerRef = useRef();
  const location = useLocation();

  const cleanPathName = useMemo(() => {
    if (location.pathname.split('/')[1] === 'pages') return '/pages';
    if (location.pathname.split('/')[1] === 'promo') return '/promo';
    if (location.pathname.split('/')[2] === 'team')
      return '/direct-lending/team';
    if (location.pathname.slice(-1) !== '/') return `${location.pathname}/`;
    return location.pathname;
  }, [location]);

  const config = configs?.[cleanPathName] || configs.default;

  useEffect(() => {
    if (navOpen) {
      setNavOpen(false);
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: 'UPDATE_HEADER_HEIGHT',
        headerHeight: headerRef.current.clientHeight - 1,
      });
    }, 100);
  }, [window.location.pathname]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_HEADER_HEIGHT',
      headerHeight: headerRef.current.clientHeight - 1,
    });
  }, [headerRef]);

  useEffect(() => {
    const handleWindowResize = e => {
      if (resizeTimeout) {
        setResizeTimeout(clearTimeout(resizeTimeout));
      }

      setResizeTimeout(
        setTimeout(() => {
          dispatch({
            type: 'UPDATE_HEADER_HEIGHT',
            headerHeight: headerRef.current.clientHeight,
          });
        }, 500)
      );
    };
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
      <HeaderStyles
        ref={headerRef}
        scrollDirection={scrollDirection}
        isOpen={navOpen}
        isDark={config?.isDark}
        $ignoreShadow={theme.config.header.ignoreShadows.some(
          s => cleanPathName.slice(0, 6) === s
        )}
        overrideBreakpoint={
          location.pathname.split('/')[2] === 'team'
            ? theme.breakpoints.tablet
            : false
        }
      >
        {config?.preHeader}
        <div className='inner'>
          <div className='mobile_header'>
            {config?.mobileHeader}
            {config?.mobileMenu && (
              <button
                className='mobile_toggle'
                type='button'
                aria-label='Toggle Menu'
                onClick={toggleMenu}
              >
                <MenuToggle isOpen={navOpen} />
              </button>
            )}
          </div>
          <div className='desktop_header'>{config?.desktopHeader}</div>
          {config?.mobileMenu && (
            <div className='mobile_menu'>
              {cloneElement(config?.mobileMenu, {
                closeMenu: () => setNavOpen(false),
                isOpen: navOpen,
              })}
            </div>
          )}
        </div>
      </HeaderStyles>
      <div
        style={{
          height: '300px',
          maxHeight: `${headerHeight}px`,
          transition: 'all .4s ease-in-out',
        }}
      />
    </>
  );
}

Header.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object),
  closeAlert: PropTypes.func,
  hidden: PropTypes.bool,
};

export default Header;
