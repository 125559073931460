import styled from 'styled-components';

export default styled.section`
  display: none;
  background: ${props => props.theme.colors.grey6};
  padding: 0.5rem 1.3125rem;

  a:focus {
    outline: revert;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: block;
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    max-width: ${props => props.theme.maxWidths.header};
    margin: 0 auto;

    li {
      padding: 0 1.1875rem;
      margin: 0;
      border-right: solid 1px ${props => props.theme.colors.grey3};

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border: none;
      }

      a, button {
        font-size: ${props => props.theme.type.label.large};
        font-family: ${props => props.theme.fonts.primary};
        font-weight: 700;
        line-height: 1em;
        padding: 0.28125rem 0;
        display: block;
        color: ${props => props.theme.colors.grey1};
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        border: none;
        background: transparent;
        outline: none;

        &:hover,
        &:active,
        &:focus {
          color: ${props => props.theme.colors.vmfBlue};
        }
      }
    }
  }
`;
