import styled from 'styled-components';

export default styled.section`
  display: flex;
  flex-direction: row;

  .logo {
      padding: 0.8125rem 0;

    a {
      display: block;

      p {
        position: absolute;
        left: -9999999999px;
      }
    }

    svg {
      display: block;

      path {
        transition: all 0.2s ease-in-out;
        fill: ${props => (props.isDark ? props.theme.colors.white : null)};
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .primary {
    max-width: ${props => props.theme.maxWidths.menuMobile};
    margin: auto;
    width: auto;
    flex: 1 1 auto;

    li {
      padding: 0;
      margin: 0;
      transition: all 0.4s ease-in-out;

      a {
        text-decoration: none;
        font-size: ${props => props.theme.type.paragraph.intro};
        color: ${props => props.theme.colors.grey1};
        padding: 0.84375rem 0;
        display: inline-block;
        transition: color 0.3s ease-in-out;

        &:hover,
        &:focus,
        &:active {
          color: ${props => props.theme.colors.vmfBlueLight};
        }
      }

      &:first-child {
        a {
          padding-top: 0;
        }
      }

      &:last-child {
        a {
          padding-bottom: 0;
        }
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.desktop}) {
      width: 100%;
    }

    @media (min-width: ${props =>
        props.theme.breakpoints.desktopMed}) and (max-width: ${props =>
        props.theme.breakpoints.desktopLarge}) {
      padding: 0 3vw;

      li {
        a {
          margin: 0 1.5vw;
        }
      }
    }

    @media (min-width: ${props =>
        props.theme.breakpoints.desktop}) and (max-width: ${props =>
        props.theme.breakpoints.desktopMed}) {
      padding: 0 2vw;

      li {
        a {
          margin: 0 0.85vw;
        }
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.desktopLarge}) {
      padding: 0 2.9375rem;

      li {
        a {
          margin: 0 1.46875rem;
        }
      }
    }
  }

  .secondary {
    display: flex;
    flex-direction: row;
    max-width: ${props => props.theme.maxWidths.menuMobile};
    margin: 1.8125rem auto 0;
    width: auto;
    // flex: 0 0 auto;
    transition: all 0.4s ease-in-out;

    > a {
      max-width: 8.8125rem;
      margin: 0 0.5rem;
      text-align: center;
      display: inline-block;
      line-height: 1rem;
      transform: translateZ(0);

      &:first-child {
        color: ${props => props.theme.colors.vmfBlue};
      }

      @media (max-width: ${props => props.theme.breakpoints.desktop}) {
        padding-left: 0;
        padding-right: 0;
        width: calc(50% - 0.25rem);

        &:first-child {
          border: solid 1px ${props => props.theme.colors.vmfBlue};
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.desktop}) {
      width: 100%;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    position: relative;
    top: initial;
    left: initial;
    width: auto;
    flex-grow: 1;
    z-index: ${props => (props.showContact ? 1 : 99)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    &.closed {
      transform: none;

      .primary {
        li {
          opacity: 1;
        }
      }

      .secondary {
        opacity: 1;
      }
    }

    .primary {
      margin: 0;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      max-width: none;

      li {
        a {
          padding: 1.46875rem 0;
          transition: all 0.2s ease-in-out;
          color: ${props =>
            props.isDark
              ? props.theme.colors.white
              : props.theme.colors.darkBlue};
          font-size: ${props => props.theme.type.caption};
        }
      }

      li {
        &:first-child {
          a {
            margin-left: 0;
            padding-top: 1.46875rem;
          }
        }

        &:last-child {
          a {
            margin-right: 0;
            padding-bottom: 1.46875rem;
          }
        }
      }
    }

    .secondary {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      padding-right: 1.1875rem;

      a {
        white-space: nowrap;
      }
    }
  }
`;
