import React from 'react';
import Styles from './styled.js';
import noop from '../../../../util/noop';
import { NavLink } from 'react-router-dom';
import VMFLogo from '../../../VMFLogo/VMFLogo.js';
import useGTM from '../../../../hooks/useGTM.js';
import ButtonLink from '../../../Shared/ButtonLink.js';
import { getBaseExpressAppUrl } from '../../../../util/config.js';
import createGTMClass from '../../../../util/createGTMClass';

export default ({
  hideButtons = false,
  hideNavigation = false,
  isDark = false,
  isLandingPage = false,
  children,
}) => {
  const [triggerGTMEvent] = useGTM();

  return (
    <Styles isDark={isDark}>
      <div className='logo'>
        <NavLink to='/' className={createGTMClass('vmf', 'header', 'logo')}>
          <VMFLogo style='color' />
          <p>Home</p>
        </NavLink>
      </div>
      {!hideNavigation && (
        <ul className='primary'>
          <li className='menu-item'>
            <NavLink
              to='/home-loans/'
              className={createGTMClass('link', 'header', 'button')}
            >
              Mortgages & Loans
            </NavLink>
          </li>
          <li className='menu-item'>
            <NavLink
              to='/home-buying/'
              className={createGTMClass('link', 'header', 'button')}
            >
              Home Buying
            </NavLink>
          </li>
          <li className='menu-item'>
            <NavLink
              to='/home-owning/'
              className={createGTMClass('link', 'header', 'button')}
            >
              Home Owning
            </NavLink>
          </li>
          <li className='menu-item'>
            <NavLink
              to='/calculators/mortgage-calculator/'
              className={createGTMClass('link', 'header', 'button')}
            >
              Calculators
            </NavLink>
          </li>
          <li className='menu-item'>
            <NavLink
              to='/resources/'
              className={createGTMClass('link', 'header', 'button')}
            >
              Resources
            </NavLink>
          </li>
          <li className='menu-item'>
            <NavLink
              to='/contact-us/'
              className={createGTMClass('link', 'header', 'button')}
            >
              Contact
            </NavLink>
          </li>
          <li className='menu-item'>
            <a
              onClick={triggerGTMEvent({
                name: 'vmf.cta_click',
                location: 'All Pages',
                category: 'job_application',
                action: 'nav',
                label: 'careers',
              })}
              href='https://careers.claytonhomes.com/our-businesses/vanderbilt-mortgage'
              target='_blank'
              rel='noreferrer noopener'
              className={createGTMClass('link', 'header', 'button')}
            >
              Careers
            </a>
          </li>
        </ul>
      )}
      {!hideButtons && (
        <div className='secondary'>
          <ButtonLink
            to={getBaseExpressAppUrl() + '/portal/login'}
            appearance='light'
            hoverAppearance='dark'
            size='small'
            isExternal={true}
            newTab={true}
            className={createGTMClass('sign_in', 'header', 'button')}
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'All Pages',
              category: 'account_sign_in',
              action: 'nav',
              label: 'sign_in',
            })}
          >
            Sign In
          </ButtonLink>
          <ButtonLink
            to={`/applynow/welcome?ReferringTM=null`}
            appearance='dark'
            size='small'
            isExternal={true}
            newTab={true}
            className={createGTMClass('apply', 'header', 'button')}
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'All Pages',
              category: 'loan_application',
              action: 'nav',
              label: 'apply',
            })}
          >
            Apply Today
          </ButtonLink>
        </div>
      )}
      {children}
    </Styles>
  );
};
