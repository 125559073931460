import React from 'react';
import useGTM from '../../../hooks/useGTM';
import PhoneIcon from '../../Shared/Icons/Phone';
import Styles from './styled';

const landingPageLocations = {
  '/applynow/welcome/': 'Application Page 1',
  '/applynow/welcome': 'Application Page 1',
  '/applynow/welcome2/': 'Application Page 2',
  '/applynow/welcome2': 'Application Page 2',
};

export default ({ isDark = false }) => {
  const [triggerGTMEvent] = useGTM();

  return (
    <Styles isDark={isDark}>
      <a
        href='tel:866-701-0467'
        onClick={triggerGTMEvent({
          name: 'vmf.cta_click',
          location: landingPageLocations[window.location.pathname],
          category: 'contact_us',
          action: 'mobile',
          label: 'click_to_call',
        })}
      >
        <PhoneIcon />
        866-701-0467
      </a>
    </Styles>
  );
};
