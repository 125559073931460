import styled from 'styled-components';

export default styled.section`

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .menu-section {
    padding: 1.635rem 1.5313rem;
  }

  .back {
    display: block;
    margin-top: 1.9625rem;
  }

  .primary {
    a {
      color: ${props => props.theme.colors.darkBlue};
    }
  }

  .secondary {
    a {
      color: ${props => props.theme.colors.vmfBlue};

      &.back {
        color: ${props => props.theme.colors.darkBlue};
      }
    }
  }

`;
