export default (
  gtmEvent = '',
  gtmLocation = '',
  gtmAction = '',
  additionalClasses = []
) => {
  if (gtmEvent && gtmLocation && gtmAction) {
    let action = gtmAction;

    if (gtmAction === 'Text Link') action = 'text';
    else if (gtmAction === 'Button') action = 'button';

    const generatedClass = `gtm_${gtmEvent}_${gtmLocation}_${action}`;
    const classes = [
      generatedClass,
      ...(typeof additionalClasses === 'string'
        ? [additionalClasses]
        : additionalClasses),
    ];
    return classes.join(' ');
  }

  return [
    ...(typeof additionalClasses === 'string'
      ? [additionalClasses]
      : additionalClasses),
  ].join(' ');
};
