import React from 'react';
import Styles from './styled';
import { Link } from 'react-router-dom';
import useGTM from '../../../../hooks/useGTM';
import createGTMClass from '../../../../util/createGTMClass';

export default ({
  phoneNumber = '1-888-893-8792',
  email = 'EnergyRightLoans@vmf.com',
}) => {
  const [triggerGTMEvent] = useGTM();

  const handleVMFLinkClick = () => {
    // triggerGTMEvent({
    //   name: 'vmf.cta_click',
    //   location: '',
    //   category: 'loan_education',
    //   action: 'nav',
    //   label: 'vmf.com_text',
    // })();
  };

  return (
    <Styles>
      <ul>
        <li>
          <Link
            className={createGTMClass('link', 'preheader', 'text')}
            onClick={handleVMFLinkClick}
            to='/'
          >
            VMF.com
          </Link>
        </li>
        <li className='energy-right-header-contact'>
          Questions? Call{' '}
          <a
            href={`tel:${phoneNumber.replace(/-/gim, '')}`}
            className={createGTMClass('phone', 'preheader', 'text')}
          >
            {phoneNumber}
          </a>
          &nbsp;or email&nbsp;<a href={`mailto:${email}`}>{email}</a>
        </li>
      </ul>
    </Styles>
  );
};
