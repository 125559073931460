const triggerEvent =
  ({ name, ...parameters }) =>
  () => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: name,
        event_name: name,
        ...parameters,
      });
    }
  };

export default () => [triggerEvent];
