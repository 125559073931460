import styled from 'styled-components';

export default styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.34625rem;

  svg {
    margin-right: 0.25rem;
  }

  a {
    display: flex;
    align-items: center;
    font-family: futura-pt;
    font-weight: 800;
    font-size: ${props => props.theme.type.h5};
    line-height: 19px;
    letter-spacing: -0.25px;
    color: ${props => props.theme.colors.vmfBlue};
    text-decoration: none;
  }
`;
