import React from 'react';
import Styles from './styled.js';
import noop from '../../../../util/noop';
import { NavLink } from 'react-router-dom';
import VMFLogo from '../../../VMFLogo/VMFLogo.js';
import useGTM from '../../../../hooks/useGTM.js';
import ButtonLink from '../../../Shared/ButtonLink.js';
import { getBaseExpressAppUrl } from '../../../../util/config.js';
import createGTMClass from '../../../../util/createGTMClass';

const landingPageLocations = {
  '/applynow/welcome/': 'Application Page 1',
  '/applynow/welcome': 'Application Page 1',
  '/applynow/welcome2/': 'Application Page 2',
  '/applynow/welcome2': 'Application Page 2',
};

export default ({
  hideButtons = false,
  hideNavigation = false,
  isDark = false,
  isLandingPage = false,
  children,
  headerLogoUrl = '/',
}) => {
  const [triggerGTMEvent] = useGTM();

  return (
    <Styles isDark={isDark}>
      <div className='logo'>
        <NavLink
          to={headerLogoUrl}
          className={createGTMClass('vmf', 'header', 'logo', [])}
          onClick={
            isLandingPage
              ? triggerGTMEvent({
                  name: 'vmf.cta_click',
                  location: landingPageLocations[window.location.pathname],
                  category: 'brand_evaluation',
                  action: 'logo',
                  label: 'vmf_homepage_exit',
                })
              : null
          }
        >
          <VMFLogo style='color' />
          <p>Home</p>
        </NavLink>
      </div>
      {!hideNavigation && (
        <ul className='primary'>
          <li className='menu-item'>
            <NavLink
              className={createGTMClass('link', 'header', 'text', [])}
              to='/home-loans/'
            >
              Mortgages & Loans
            </NavLink>
          </li>
          <li className='menu-item'>
            <NavLink
              className={createGTMClass('link', 'header', 'text', [])}
              to='/home-buying/'
            >
              Home Buying
            </NavLink>
          </li>
          <li className='menu-item'>
            <NavLink
              className={createGTMClass('link', 'header', 'text', [])}
              to='/home-owning/'
            >
              Home Owning
            </NavLink>
          </li>
          <li className='menu-item'>
            <NavLink
              className={createGTMClass('link', 'header', 'text', [])}
              to='/calculators/mortgage-calculator/'
            >
              Calculators
            </NavLink>
          </li>
          <li className='menu-item'>
            <NavLink
              className={createGTMClass('link', 'header', 'text', [])}
              to='/resources/'
            >
              Resources
            </NavLink>
          </li>
          <li className='menu-item'>
            <NavLink
              className={createGTMClass('link', 'header', 'text', [])}
              to='/contact-us/'
            >
              Contact
            </NavLink>
          </li>
          <li className='menu-item'>
            <a
              onClick={triggerGTMEvent({
                name: 'vmf.cta_click',
                location: 'All Pages',
                category: 'job_application',
                action: 'nav',
                label: 'careers',
              })}
              href='https://careers.claytonhomes.com/our-businesses/vanderbilt-mortgage'
              target='_blank'
              rel='noreferrer noopener'
              className={createGTMClass('link', 'header', 'text', [])}
            >
              Careers
            </a>
          </li>
        </ul>
      )}
      {!hideButtons && (
        <div className='secondary'>
          <ButtonLink
            to={getBaseExpressAppUrl() + '/portal/login'}
            appearance='light'
            hoverAppearance='dark'
            size='small'
            isExternal={true}
            newTab={true}
            className={createGTMClass('sign_in', 'header', 'button', [])}
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'All Pages',
              category: 'account_sign_in',
              action: 'nav',
              label: 'sign_in',
            })}
          >
            Sign In
          </ButtonLink>
          <ButtonLink
            to='/applynow/welcome/'
            appearance='dark'
            size='small'
            isExternal={true}
            newTab={true}
            className={createGTMClass('apply', 'header', 'button', [])}
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'All Pages',
              category: 'loan_application',
              action: 'nav',
              label: 'apply',
            })}
          >
            Apply Today
          </ButtonLink>
        </div>
      )}
      {children}
    </Styles>
  );
};
