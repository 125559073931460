import theme from '../theme';

const styles = [
  {
    regex: /^(VMF Blue Button).*\/.*(White Text).*/gim,
    css: `
      color: ${theme.colors.white};
      background: ${theme.colors.vmfBlue};
      border: 1px solid ${theme.colors.vmfBlue};

      &:not(:disabled) {
        &:hover,
        &:active,
        &:focus {
          color: ${theme.colors.vmfBlue};
          background: ${theme.colors.white};
          border: 1px solid ${theme.colors.vmfBlue};
        }
      }
    `,
    inverse: `
      color: ${theme.colors.vmfBlue};
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.vmfBlue};
  
      &:not(:disabled) {
        &:hover,
        &:active,
        &:focus {
          color: ${theme.colors.white};
          background: ${theme.colors.vmfBlue};
          border: 1px solid ${theme.colors.vmfBlue};
        }
      }
    `,
  },
  {
    regex: /^(Dark Blue Button).*\/.*(White Text).*/gim,
    css: `
      color: ${theme.colors.white};
      background: ${theme.colors.darkBlue};
      border: 1px solid ${theme.colors.darkBlue};

      &:not(:disabled) {
        &:hover,
        &:active,
        &:focus {
          color: ${theme.colors.darkBlue};
          background: ${theme.colors.white};
          border: 1px solid ${theme.colors.darkBlue};
        }
      }
    `,
    inverse: `
      color: ${theme.colors.darkBlue};
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.darkBlue};
  
      &:not(:disabled) {
        &:hover,
        &:active,
        &:focus {
          color: ${theme.colors.white};
          background: ${theme.colors.darkBlue};
          border: 1px solid ${theme.colors.darkBlue};
        }
      }
    `,
  },
  {
    regex: /^(White Button).*\/.*(Blue Text).*/gim,
    css: `
      color: ${theme.colors.darkBlue};
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.white};

      &:not(:disabled) {
        &:hover,
        &:active,
        &:focus {
          color: ${theme.colors.white};
          background: ${theme.colors.vmfBlue};
          border: 1px solid ${theme.colors.white};
        }
      }
    `,
    inverse: `
      color: ${theme.colors.white};
      background: ${theme.colors.vmfBlue};
      border: 1px solid ${theme.colors.vmfBlue};

      &:not(:disabled) {
        &:hover,
        &:active,
        &:focus {
          color: ${theme.colors.vmfBlue};
          background: ${theme.colors.white};
          border: 1px solid ${theme.colors.vmfBlue};
        }
      }
    `,
  },
];

export default contentfulBtnColorString => {
  const found = styles.find(style =>
    `${contentfulBtnColorString}`.match(style.regex)
  );
  return found || styles[0];
};
