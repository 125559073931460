import styled from 'styled-components';

export default styled.section`
  .primary {
    padding: 2rem 1.5625rem 2.6875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: solid 1px ${props => props.theme.colors.grey5};

    .button-link {
      display: block;
      width: 100%;
      margin-bottom: 1rem;
      text-align: center;
      max-width: 20.1875rem;
      box-sizing: border-box;
    }

    a.contact {
      display: flex;
      height: 100%;
      align-items: center;
      font-size: ${props => props.theme.type.h5};
      font-family: ${props => props.theme.fonts.secondary};
      font-weight: 800;
      color: ${props =>
        props.isDark ? props.theme.colors.white : props.theme.colors.vmfBlue};
      text-decoration: none;
      margin: 1.5625rem 0 0;

      svg {
          margin-right: .5rem;
      }
    }
  }

  .secondary {
    padding: 2rem 1.5625rem 2.875rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 20.1875rem;
    margin: auto;

    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
        align-items: center;
    }

    a, button {
        font-size: ${props => props.theme.type.paragraph.default};
        text-decoration: none;
        color: ${props => props.theme.colors.darkBlue};
        margin: 0.71875rem 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    button {
        display: inline-block;
        font-family: ${props => props.theme.fonts.primary};
        border: none;
        padding: 0;
        background: transparent;
    }
  }
`;
