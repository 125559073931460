// Use this way of getting constants to account for
// transforms in the build process (Octo dependency)
export const getEnvironment = () =>
  '#{EnvironmentCode}'.indexOf('EnvironmentCode') > 0
    ? 'dev'
    : '#{EnvironmentCode}';
export const GTM_ID = '#{GTM_ID}';
export function getGtmId() {
  return GTM_ID.indexOf('GTM_ID') < 0 ? GTM_ID : 'GTM-TW9CJBV';
}

export const GTAG_ID = '#{GTAG_ID}';
export const getgtagId = () =>
  GTAG_ID.indexOf('GTAG_ID') < 0 ? GTAG_ID : 'G-WKH39M4JCY';

export const BASE_AWS_API_URL = '#{BASE_AWS_API_URL}';
export const getBaseApiUrl = () =>
  '#{BASE_AWS_API_URL}'.indexOf('BASE_AWS_API_URL') > 0
    ? 'https://api-vmf-com.dev.cct-vmf.com'
    : '#{BASE_AWS_API_URL}';

export const EnvironmentCode = '#{EnvironmentCode}';
export const getEnvironmentCode = () =>
  '#{EnvironmentCode}'.indexOf('EnvironmentCode') > 0
    ? 'dev'
    : '#{EnvironmentCode}';

export const getBaseOnlineAppUrl = () =>
  '#{BASE_ONLINE_APP_URL}'.indexOf('BASE_ONLINE_APP_URL') > 0
    ? 'https://itg.vmf.com'
    : '#{BASE_ONLINE_APP_URL}';

export const getBaseChApiUrl = () =>
  '#{BASE_CH_API_URL}'.indexOf('BASE_CH_API_URL') > 0
    ? 'https://api.claytonhomes.com' // use 'https://dev.cmhinfo.biz/WebServices2/api' after fw issue resolved
    : '#{BASE_CH_API_URL}';

export const getBaseEscoreApiUrl = () =>
  '#{ESCORE_API_URL}'.indexOf('ESCORE_API_URL') > 0
    ? 'https://www.vanderbiltenergyright.com'
    : '#{ESCORE_API_URL}';

export const getBaseExpressAppUrl = () =>
  '#{BASE_EXPRESS_APP_URL}'.indexOf('BASE_EXPRESS_APP_URL') > 0
    ? 'https://vmf.com'
    : '#{BASE_EXPRESS_APP_URL}';

export const getBaseHugUrl = () =>
  '#{BASE_HUG_URL}'.indexOf('BASE_HUG_URL') > 0
    ? 'https://vmf.com'
    : '#{BASE_HUG_URL}';

export const LAMBDA_TOKEN = '#{LAMBDA_TOKEN}';
export const getLambdaToken = () =>
  LAMBDA_TOKEN.indexOf('LAMBDA_TOKEN') > 0
    ? 'nRn43mTWwmeEmcjn4isnlsnc'
    : LAMBDA_TOKEN;

export const getRequestHeaders = () => ({
  'lambda-token': getLambdaToken(),
});
