const colors = {
  darkBlue: '#0E2551',
  vmfBlue: '#004B8C',
  vmfBlueLight: '#176DD7',
  midnight: '#1C1239',
  mimosa: '#F68C2C',
  summerNight: '#352269',
  lavenderFields: '#796CB2',
  smokyMountains: '#8D83BE',
  babyBlueTint: '#C5E9FE',
  babyBlue: '#91CCEF',
  bedtime: '#06292F',
  vmfGreen: '#00A550',
  vmfGreen2: '#2C8641',
  vmfRed: '#D04326',
  yellow: '#FDBB3D',
  grey1: '#4E5255',
  grey2: '#777B7E',
  grey3: '#A1A3A6',
  grey4: '#CACCCF',
  grey5: '#E4E5E7',
  grey6: '#F3F5F7',
  grey7: '#F7F9FB',
  grey8: '#E0E0E0',
  grey9: '#969696',
  white: '#FFFFFF',
  offWhite: '#ECEFE8',
  black: '#000000',
  blackAndGrey: '#25292D',
  bigSky: '#00A0DD',
  lowDownGreen: '#2C545E',
  takeFiveBlue: '#1745B0',
  hotPink: '#F210B3',
  lightPink: '#FF9EE6',
  landDownBlue: '#0075C9',
};

const fonts = {
  // Body
  primary: 'nunito, Arial, sans-serif',
  // Headers
  secondary: 'futura-pt, Arial, sans-serif',
};

const type = {
  large: '3.75rem',
  largeMobile: '2.5rem',
  h1: '2.5rem',
  h1Mobile: '2.125rem',
  h2: '2rem',
  h2Mobile: '1.625rem',
  h3: '1.56rem',
  h3Mobile: '1.375rem',
  h4: '1.25rem',
  h4Mobile: '1.125rem',
  h5: '1rem',
  h6: '.75rem',
  label: {
    large: '.6875rem',
    small: '.5625rem',
  },
  paragraph: {
    intro: '1.125rem',
    default: '1rem',
    xs: '.75rem',
  },
  caption: '.875rem',
  button: {
    small: '.875rem',
    large: '1rem',
  },
};

const letterSpacing = {
  large: '-1.25px',
  h1: '-1px',
  h2: '-.5px',
  h3: '-.5px',
  h4: '-.25px',
  h5: '-.25px',
  h6: '-.25px',
  label: '.5px',
  paragraph: '.5px',
  caption: '.5px',
};

const lineHeights = {
  large: '1.07em',
  largeMobile: '1.175em',
  h1: '1.125em',
  h1Mobile: '1.147em',
  h2: '1.125em',
  h2Mobile: '1.154em',
  h3: '1.12em',
  h3Mobile: '1.35em',
  h4: '1.15em',
  h4Mobile: '1.67em',
  h5: '1.1875em',
  h6: '1.167em',
  label: {
    large: '1.182em',
    small: '1.33em',
  },
  paragraph: {
    intro: '1.78em',
    default: '1.6875em',
    xs: '1rem',
  },
  caption: {
    default: '1.57em',
    medium: '1.375rem',
    tight: '1.257em',
  },
  button: {
    small: '1.0625rem',
    large: '1.1875rem',
  },
};

// Add other utilities here
// can reference previously defined colors + fonts
const shadows = {
  tooltip:
    '0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 2px 0px rgba(0, 0, 0, 0.07), 0px 4px 8px 0px rgba(0, 0, 0, 0.05)',
  card: '0px 0px 1px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.05)',
  low: '0px 0px 1px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.07), 0px 4px 8px rgba(0, 0, 0, 0.05)',
  mid: '0px 0px 1px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.05)',
  high: '0px 0px 1px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 16px 24px rgba(0, 0, 0, 0.08)',
};

const heights = {
  heroOverlapBackdropMobile: '185px',
  featuretteListPicture: '603px',
};

const maxHeights = {
  heroOverlapBackdrop: '461px',
};

const borderRadiuses = {
  card: '4px',
  tooltip: '2px',
};

const maxWidths = {
  hugContent: '1150px',
  heroOverlap: '706px',
  heroOverlapFull: '1016px',
  accordion: '1016px',
  container: '1224px',
  containerMedium: '1016px',
  featurette: '1150px',
  containerSmall: '512px',
  postsColumn: '288px',
  ctaCardCondensed: '1016px',
  ctaCard: '1217px',
  heroOverlapTablet: '632px',
  content: '808px',
  blurbsThree: '1182px',
  modal: '1184px',
  calculatorContent: '288px',
  alerts: '1440px',
  alert: '750px',
  header: '1440px',
  menuMobile: '508px',
  footerMenu: '512px',
  ctaModalImage: '770px',
  ctaContact: '1014px',
  hugSearch: '600px',
  ctaSimple: '808px',
  formOverlap: '808px',
  fourOhFour: '1126px',
  blurbsFourContent: '896px',
  calculator: '1016px',
  calculatorFields: '390px',
  calculatorResults: '496px',
  postSmall: '381px',
  post: '808px',
  popularFaqsContent: '392px',
  popularFaqsList: '600px',
  faqsMenu: '296px',
  sidebar: '288px',
  cardCoupon: '392px',
  couponSearch: '392px',
  usefulLinks: '336px',
  ccpa: '550px',
  licenses: '1016px',
  homeLoanOverview: '1014px',
  featuretteListPicture: '423px',
  ppcPages: '1222px',
  tvaEnergyRightLandingPage: '1227.34px',
};

const breakpoints = {
  desktopLarge: '1440px',
  desktopMedLarge: '1150px',
  desktopMed: '1024px',
  desktop: '992px',
  tablet: '640px',
  mobile: '480px',
  mobileSmall: '360px',
};

const padding = {
  inner: '1.5625rem',
  innerMid: '1.5rem',
  innerLarge: '2rem',
  button: {
    large: '16px 22px',
    small: '.875rem 1.125rem',
  },
};

const animations = {
  loadingEnter: `
    @keyframes loadingEnter {
      0% {
        position: absolute;
        z-index: 9950;
        opacity: 0;
      }
      100% {
        position: absolute;
        opacity: 1;
      }
    }
  `,
  loadingExit: `
    @keyframes loadingExit {
      0% {
        opacity: 1;
        z-index: 9950;
      }
      99% {
        opacity: 0;
        z-index: 9950;
      }
      100% {
        opacity: 0;
        z-index: -1;
      }
    }
  `,
  sunRaysLoop: `
    @keyframes sunRaysLoop {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `,
  houseRock: `
    @keyframes houseRock {
      from {
        transform: rotate(-10deg);
      }
      to {
        transform: rotate(10deg);
      }
    }
  `,
  envelopeRock: `
    @keyframes envelopeRock {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(20deg);
      }
    }
  `,
  envelopeBob: `
    @keyframes envelopeBob {
      from {
        transform: translateY(10px);
      }
      to {
        transform: translateY(-10px);
      }
    }
  `,
  fourOhFour: {
    cloudLoop: `
      @keyframes cloudLoop {
        0% {
          opacity: 1;
          transform: translateY(0);
        }
        50% {
          opacity: 1;
          transform: translateY(-100%);
        }
        50.1% {
          opacity: 0;
          transform: translateY(-100%);
        }
        50.2% {
          opacity: 0;
          transform: translateY(100%);
        }
        50.3% {
          opacity: 1;
          transform: translateY(100%);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    `,
    backgroundLoop: `
      @keyframes backgroundLoop {
        0% {
          transform: skew(2deg, -3deg);
        }
        50% {
          transform: skew(4deg, 0deg);
        }
        100% {
          transform: skew(-2deg, 3deg);
        }
      }
    `,
  },
  fadeIn: `
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `,
  drawIn: `
    @keyframes drawIn {
      from {
        stroke-dasharray: 500;
        stroke-dashoffset: 500;
      }
      to {
        stroke-dasharray: 500;
        stroke-dashoffset: 0;
      }
    }
  `,
  scaleIn: `
    @keyframes scale-in {
      0% {
        opacity: 0;
        transform: scale(1.2);
      }
      100% {
        opacity: 1;
        transform: none;
      }
    }
  `,
  slideInUp: `
    @keyframes slide-in-up {
      0% {
        opacity: 0;
        transform: translateY(10px);
      }
      100% {
        opacity: 1;
        transform: none;
      }
    }
  `,
  slideInDown: `
    @keyframes slide-in-down {
      0% {
        opacity: 0;
        transform: translateY(-10px);
      }
      100% {
        opacity: 1;
        transform: none;
      }
    }
  `,
  slideInRight: `
    @keyframes slide-in-right {
      0% {
        opacity: 0;
        transform: translateX(-10px);
      }
      100% {
        opacity: 1;
        transform: none;
      }
    }
  `,
};

const config = {
  header: {
    ignoreShadows: ['/promo'],
  },
};

const theme = {
  colors,
  fonts,
  type,
  letterSpacing,
  lineHeights,
  shadows,
  borderRadiuses,
  maxHeights,
  maxWidths,
  breakpoints,
  padding,
  heights,
  animations,
  config,
};

export default theme;
