import styled from 'styled-components'

const FooterPromoBackLinkStyles = styled.section`
    display: ${props => props.isPromoPage ? "none" : "block"}
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1.75rem;
    background-color: white;
    

    button {
      border: none;
      background: white;
      color: ${props => props.isPromoPage ? props.theme.colors.landDownBlue : props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? props.theme.colors.vmfBlue : props.theme.colors.white};
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: ${props => props.isPromoPage ? `"acumin-pro", sans-serif` : props.theme.fonts.secondary};
      ${props => props.isPromoPage ? `font-weight: 700;` : ""}
      font-size: ${props => props.theme.type.h5};
      padding: 0;
      margin: 0;
      cursor: pointer;

      svg {
        ${props => props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? `display: none;` : ''}
      }
    }
`

export default FooterPromoBackLinkStyles