export const breakpoints = {
  desktop: '992px',
  tablet: '640px',
};

export const maxWidths = {
  headingInner: '808px',
  calculator: '1098px',
  container: '1224px',
};

export const borderRadiuses = {
  card: 0,
};

export const padding = {
  card: '50px 50px 78px',
};
