import React from 'react';
import Styles from './styled';
import { NavLink } from 'react-router-dom';
import useGTM from '../../../../hooks/useGTM';
import noop from '../../../../util/noop';
import ButtonLink from '../../../Shared/ButtonLink';
import { getBaseExpressAppUrl } from '../../../../util/config';
import createGTMClass from '../../../../util/createGTMClass';

export default ({ closeMenu = noop, hideButtons = false, isOpen = false }) => {
  const [triggerGTMEvent] = useGTM();

  return (
    <Styles>
      <ul className='primary'>
        <li className='menu-item'>
          <NavLink
            onClick={closeMenu}
            to='/home-loans/'
            tabIndex={!isOpen ? -1 : null}
            className={createGTMClass('link', 'header', 'text', [])}
          >
            Mortgages & Loans
          </NavLink>
        </li>
        <li className='menu-item'>
          <NavLink
            onClick={closeMenu}
            to='/home-buying/'
            tabIndex={!isOpen ? -1 : null}
            className={createGTMClass('link', 'header', 'text', [])}
          >
            Home Buying
          </NavLink>
        </li>
        <li className='menu-item'>
          <NavLink
            onClick={closeMenu}
            to='/home-owning/'
            tabIndex={!isOpen ? -1 : null}
            className={createGTMClass('link', 'header', 'text', [])}
          >
            Home Owning
          </NavLink>
        </li>
        <li className='menu-item'>
          <NavLink
            onClick={closeMenu}
            to='/calculators/mortgage-calculator/'
            tabIndex={!isOpen ? -1 : null}
            className={createGTMClass('link', 'header', 'text', [])}
          >
            Calculators
          </NavLink>
        </li>
        <li className='menu-item'>
          <NavLink
            onClick={closeMenu}
            to='/resources/'
            tabIndex={!isOpen ? -1 : null}
            className={createGTMClass('link', 'header', 'text', [])}
          >
            Resources
          </NavLink>
        </li>
        <li className='menu-item'>
          <NavLink
            onClick={closeMenu}
            to='/contact-us/'
            tabIndex={!isOpen ? -1 : null}
            className={createGTMClass('link', 'header', 'text', [])}
          >
            Contact
          </NavLink>
        </li>
        <li className='menu-item'>
          <a
            onClick={() => {
              closeMenu();
              triggerGTMEvent({
                name: 'vmf.cta_click',
                location: 'All Pages',
                category: 'job_application',
                action: 'nav',
                label: 'careers',
              })();
            }}
            href='https://careers.claytonhomes.com/our-businesses/vanderbilt-mortgage'
            target='_blank'
            rel='noreferrer noopener'
            tabIndex={!isOpen ? -1 : null}
            className={createGTMClass('link', 'header', 'text', [])}
          >
            Careers
          </a>
        </li>
      </ul>
      {!hideButtons && (
        <div className='secondary'>
          <ButtonLink
            to={getBaseExpressAppUrl() + '/portal/login'}
            appearance='light'
            hoverAppearance='dark'
            size='small'
            isExternal={true}
            newTab={true}
            onClick={triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'All Pages',
              category: 'account_sign_in',
              action: 'nav',
              label: 'sign_in',
            })}
            tabIndex={!isOpen ? -1 : null}
            className={createGTMClass('sign_in', 'header', 'button', [])}
          >
            Sign In
          </ButtonLink>
          <ButtonLink
            to='/applynow/welcome/'
            appearance='dark'
            size='small'
            isExternal={true}
            newTab={true}
            onClick={() => {
              triggerGTMEvent({
                name: 'vmf.cta_click',
                location: 'All Pages',
                category: 'loan_application',
                action: 'nav',
                label: 'apply',
              })();
              closeMenu();
            }}
            tabIndex={!isOpen ? -1 : null}
            className={createGTMClass('apply', 'header', 'button', [])}
          >
            Apply Today
          </ButtonLink>
        </div>
      )}
    </Styles>
  );
};
